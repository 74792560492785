<template>
  <component
    v-if="itemRevision"
    v-bind:is="component"
    :value="itemRevision"
    @select="selectTag"
    :selectedTag="selectedTag"
    :from="$route.query.from"
    :records="records"
    @event="e => records.push(e)"
    :view.sync="current_view"
    mode="view"
    @createContentByType="createContentByType"
    :profile="profile" />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { Extensions } from "plant-common"
import { ItemViewer } from "plant-common"
import Loading from "../_layout/Loading"
import guideItemViewer from "@/components/guideCommon/guide"
import guideTemplateItemViewer from "@/components/guideCommon/guide-template"
import lessonPlanViewer from "@/components/lessonPlan"
import interactiveSlideViewer from "@/components/interactiveSlide"
import interactiveBookViewer from "@/components/interactiveBook"
import interactiveTextViewer from "@/components/interactiveText"
import changeDocumentTitleAndIcon from "@/utils/changeDocumentTitleAndIcon.js"
import {
  STORE_CONTENT_NAME,
  STATE_ITEM_REVISION,
  STATE_SELECTED_TAG,
  ACTION_LOAD_CONTENT,
  MUTATION_DESTROY_CONTENT,
  MUTATION_SELECT_TAG,
  MUTATION_SET_CURRENT_VIEW,
  STORE_MODULE_COMMENTS,
  STATE_CURRENT_LOCATION,
  ACTION_SAVE_CURRENT_LOCATION
} from "@/store_constants/content"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import { STORE_USER_NAME, STATE_PROFILE } from "@/store_constants/user"
import {
  getContentId,
  getContentIdFromCustomId,
  getType,
  getMajorVersion,
  getMinorVersion,
  getCustomId,
  getTitle
} from "../../../utils/dbTool"
import deepEqual from "@/utils/deepEqual"
import PtApiClient from "plant-api-client"
import { PContentLessonPlan } from "@/components/lessonPlan/models/models"
import { PContentInteractiveText } from "@/components/interactiveText/models/models"

export default {
  name: "ContentViewer",
  props: ["id", "major_revision", "minor_revision", "view", "mode"],
  data() {
    return {
      component: Loading,
      Extensions,
      records: [],
      current_view: false
    }
  },
  components: { ItemViewer },
  created() {
    this.$nextTick(() => {
      try {
        if (this.$route.hash.substr(1)) {
          this.current_view = JSON.parse(decodeURI(this.$route.hash.substr(1)))
        }
      } catch (e) {
        console.warn(`The URL hash passed can not be parsed: ${this.$route.hash.substr(1)}`)
      }
    })
  },
  computed: {
    ...mapState(STORE_CONTENT_NAME, {
      itemRevision: STATE_ITEM_REVISION,
      selectedTag: STATE_SELECTED_TAG
    }),
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    }),
    ...mapState(STORE_MODULE_COMMENTS, {
      current_location: STATE_CURRENT_LOCATION
    }),
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE
    })
  },
  methods: {
    ...mapActions(STORE_CONTENT_NAME, [ACTION_LOAD_CONTENT, MUTATION_DESTROY_CONTENT]),
    ...mapMutations(STORE_CONTENT_NAME, [MUTATION_SELECT_TAG, MUTATION_SET_CURRENT_VIEW]),
    ...mapActions(STORE_MODULE_COMMENTS, [ACTION_SAVE_CURRENT_LOCATION]),
    async goTo(custom_id) {
      if (getType(this.itemRevision) == "diagram") {
        window.open(`/content/${this.settings._id}/${await getContentIdFromCustomId(custom_id)}/latest`)
      }
    },
    async parseToContentId(itemId) {
      // Parse custom_id to mongoId (content_id)
      if (!itemId.match(/^[0-9a-fA-F]{24}$/)) {
        itemId = await getContentIdFromCustomId(itemId)
      }
      return itemId
    },
    loadContentInView(itemRevision) {
      if (!itemRevision) return
      const content_type = this.getType(itemRevision)
      // Guide and lesson plan contents placed inside app
      Extensions["guide"] = guideItemViewer
      Extensions["guideTemplate"] = guideTemplateItemViewer
      Extensions["lessonPlan"] = lessonPlanViewer
      Extensions["interactiveSlide"] = interactiveSlideViewer
      Extensions["interactiveText"] = interactiveTextViewer
      Extensions["interactiveBook"] = interactiveBookViewer
      if (Extensions[content_type] && Extensions[content_type]["Viewer"]) {
        switch (content_type) {
          case "guide":
            this.component = guideItemViewer["Viewer"]
            break
          case "guideTemplate":
            this.component = guideTemplateItemViewer["Viewer"]
            break
          case "lessonPlan":
            this.component = lessonPlanViewer["Viewer"]
            break
          case "interactiveSlide":
            this.component = interactiveSlideViewer["Viewer"]
            break
          case "interactiveText":
            this.component = interactiveTextViewer["Viewer"]
            break
          case "interactiveBook":
            this.component = interactiveBookViewer["Viewer"]
            break
          default:
            this.component = ItemViewer
            break
        }
      } else {
        this.component = {
          template: `<div>Viewer for the extension ${content_type} not found (ERR: app).</div>`
        }
      }
    },
    reloadContent() {
      let itemToLoad = {
        id: this.id,
        major_revision: this.major_revision,
        minor_revision: this.minor_revision == 0 ? "0" : this.minor_revision
      }
      this[ACTION_LOAD_CONTENT](itemToLoad)
        .then(() => {
          this.loadContentInView(this.itemRevision)
        })
        .then(() => {
          if (this.itemRevision) {
            var { title, custom_id, content_revision_type } = this.itemRevision.header.metadata
            changeDocumentTitleAndIcon(`[${custom_id}] ${title}`, Extensions[content_revision_type].icon)
          }
        })
    },
    getType: getType,
    async getUUCustomId(type, value) {
      function buildCustomId() {
        const randomNumber = Math.floor(Math.random() * 9999)
        const objCustomId = getCustomId(value)
        const letterCustomId = type === "interactiveSlide" || type === "interactiveText" ? "C" : "A"
        const custom_id = `${objCustomId}-${letterCustomId}${randomNumber}`

        return custom_id
      }

      let custom_id = buildCustomId()
      let isUUCustomId = true
      while (isUUCustomId) {
        try {
          await PtApiClient.getContentByCustomId(custom_id, true)
          custom_id = buildCustomId()
        } catch (e) {
          isUUCustomId = false
        }
      }
      return custom_id
    },
    buildPreTitle(type) {
      let preTitle = "Activity"
      if (type === "interactiveSlide") preTitle = "Interactive Slide"
      if (type === "interactiveText") preTitle = "Interactive Text"

      return preTitle
    },
    async createContentByType({ type, value }) {
      const custom_id = await this.getUUCustomId(type, value)
      const path_id = value.folder ? value.folder._id : undefined
      const preTitle = this.buildPreTitle(type)
      const title = `${preTitle} for ${getTitle(value)}`
      const user_id = this.profile._id

      const content = await PtApiClient.createContent(title, custom_id, path_id, type)
      const content_id = getContentId(content.itemRevisionDraft)

      await PtApiClient.updateDraftWorkflow(content_id, "method", "changeWorkflow", {
        name: "no_reviewer",
        lightweight: true
      })
      const draftWork = await PtApiClient.updateDraftWorkflow(content_id, "method", "reassignRole", {
        role: "author",
        user_id
      })

      let newBodyContent = {}
      const majorRevision = getMajorVersion(this.itemRevision)
      const minorRevision = getMinorVersion(this.itemRevision)
      const objectiveUrl = `plant:/content/${this.id}/${majorRevision}/${minorRevision}`

      const newObjective = Object.assign({}, value, {
        $origin: objectiveUrl
      })

      if (type === "interactiveSlide") {
        newBodyContent = new PContentLessonPlan()
      }

      if (type === "interactiveText") {
        newBodyContent = new PContentInteractiveText()
      }

      if (type === "activityMultipleChoice") {
        draftWork.body.dependencies = [objectiveUrl]
      }

      newBodyContent.instructionalSettings = {
        completionTime: 0,
        objective: newObjective
      }

      draftWork.body.contents = newBodyContent
      await PtApiClient.updateDraftBody(content_id, draftWork.body)

      window.open(`/content/${this.settings._id}/${content_id}/draft`)
    }
  },
  destroyed() {
    this[MUTATION_DESTROY_CONTENT]()
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        return this.reloadContent()
      }
    },
    major_revision: {
      immediate: true,
      handler() {
        return this.reloadContent()
      }
    },
    minor_revision: {
      immediate: true,
      handler() {
        return this.reloadContent()
      }
    },
    current_view: {
      handler() {
        if (!this.current_view) return
        window.location.hash = JSON.stringify(this.current_view)
        this[MUTATION_SET_CURRENT_VIEW](this.current_view)
        if (deepEqual(this.current_location, this.current_view)) return
        this[ACTION_SAVE_CURRENT_LOCATION](this.current_view)
      }
    },
    view: {
      handler() {
        if (!this.view) return
        this.current_view = this.view
      }
    },
    current_location: {
      deep: true,
      handler() {
        if (deepEqual(this.current_location, this.view)) return
        this.current_view = this.current_location
      }
    }
  }
}
</script>
