import Vue from "vue"
import Edit from "./Edit.js"
import Activity from "./Activity.js"
import Review from "./Review.js"
import Fullscreen from "./Fullscreen.js"
import * as models from "../../models/models.js"
import { walkTreeData } from "he-tree-vue"
import applicationBlocks from "../../blocks/_components/applicationBlocks.js"
import { getUniqueArray } from "../../utils/utils.js"
import branding from "../../../../branding.js"
import cloneDeep from "lodash.clonedeep"
import { mapActions } from 'vuex';
import { ACTION_UPDATE_ACTIVITY_STATE, STORE_MODULE_ACTIVITY_STATE } from '@/store_constants/content/index.js';

export default function (vm) {
  let contents = vm.value.body.contents
  if (!contents || Object.keys(contents).length === 0) {
    vm.value.body.contents = new models.PContentLessonPlan()
  } else {
    vm.value.body.contents = new models.PContentLessonPlan(contents)
  }

  return new Vue({
		mixins: [Edit, Activity, Review, Fullscreen],
		data() {
			return {
				vm: vm,

				mode: vm.mode,

				selectedSlide: null,
				slidesTree: null,

				// edit layouts section
				editLayouts: false,
				imported: false,

				activityState: vm.activityState ? Object.assign({}, vm.activityState) : undefined,

				gridSize: [1, 1],
				prevGridSize: undefined,
				gridSizes: [
					[1, 1],
					[40, 22.5],
					[80, 45],
					[120, 67.5],
					[160, 90],
					[320, 180]
				],

				fontFamilies: [
					{ text: 'Default', value: 'inherit' },
					{ text: 'Roboto', value: 'Roboto' },
					{ text: 'Arial', value: 'Arial' },
					{ text: 'Calibri', value: 'Calibri' },
					{ text: 'Courier', value: 'Courier' },
					{ text: 'Courier New', value: 'Courier New' },
					{ text: 'Didot', value: 'Didot' },
					{ text: 'Garamond', value: 'Garamond' },
					{ text: 'Georgia', value: 'Georgia' },
					{ text: 'Helvetica', value: 'Helvetica' },
					{ text: 'Lato', value: 'Lato' },
					{ text: 'Lucida Console', value: 'Lucida Console' },
					{ text: 'Monaco', value: 'Monaco' },
					{ text: 'Manrope', value: 'Manrope' },
					{ text: 'Optima', value: 'Optima' },
					{ text: 'Perpetua', value: 'Perpetua' },
					{ text: 'Sora', value: 'Sora' },
					{ text: 'Tahoma', value: 'Tahoma' },
					{ text: 'Times New Roman', value: 'Times New Roman' },
					{ text: 'Trebuchet MS', value: 'Trebuchet MS' },
					{ text: 'Verdana', value: 'Verdana' }
				],
				notificationHandler: {
					active: false,
					text: '',
					color: 'error'
				},
				answerHandler: {
					active: false,
					data: {}
				},
				slideNumberShown: false,
				subtitlesActive: false,
				subtitlesText: false,
				defaultSubtitles: false
			};
		},
		computed: {
			gridIndex() {
				return this.gridSizes.findIndex(grid => grid[0] === this.gridSize[0]);
			},
			canGoNext() {
				if (this.selectedSlideIndex + 1 < this.slidesVisible.length) {
					const slideState = this.slideNavigationMap.find(
						slide => slide.id === this.slidesVisible[this.selectedSlideIndex + 1].id
					);
					return slideState.available;
				}

				return false;
			},
			canGoPrev() {
				if (this.selectedSlideIndex - 1 > -1) {
					const slideState = this.slideNavigationMap.find(
						slide => slide.id === this.slidesVisible[this.selectedSlideIndex - 1].id
					);
					return slideState.available;
				}

				return false;
			},
			comments() {
				return vm.comments;
			},
			view() {
				return vm.view;
			},
			applicationBlocks() {
				return {
					title: 'Application Blocks',
					application: true,
					galleries: applicationBlocks
				};
			},
			galleryLessonPlan() {
				return {
					title: 'This Lesson Plan Blocks',
					collapsed: true,
					galleries: this.lessonPlanContents.galleries
						? this.lessonPlanContents.galleries.filter(gallery => gallery.imported === false)
						: []
				};
			},
			galleryImportedLessonPlans() {
				return {
					title: 'Imported Lesson Plan Blocks',
					application: true,
					collapsed: true,
					galleries: this.lessonPlanContents.galleries
						? this.lessonPlanContents.galleries.filter(gallery => gallery.imported !== false)
						: []
				};
			},
			galleryGroups() {
				const total = [this.applicationBlocks];
				if (this.galleryLessonPlan.galleries.length > 0) {
					total.push(this.galleryLessonPlan);
				}
				if (this.galleryImportedLessonPlans.galleries.length > 0) {
					total.push(this.galleryImportedLessonPlans);
				}
				return total;
			},
			slideArray() {
				if (!this.slidesTree) return [];
				return this.flat(this.slidesTree.slides);
			},
			slidesVisible() {
				// Remove hidden sections if not in edit mode
				if (this.mode !== 'edit') return this.slideArray.filter(slide => !slide.skip);
				return this.slideArray;
			},
			lessonPlanContents() {
				return vm.value.body.contents;
			},
			globalStyles() {
				return this.lessonPlanContents.settings.styles;
			},
			selectedSlideIndex() {
				if (this.selectedSlide) {
					return this.slidesVisible.findIndex(s => s.id === this.selectedSlide.id);
				} else {
					return null;
				}
			},
			isSelectedSection() {
				return this.isSection(this.selectedSlide);
			},
			isSelectedSlideContent() {
				return !this.isSection(this.selectedSlide);
			},
			selectedSlideObjectives() {
				if (
					!this.selectedSlide ||
					(this.selectedSlide.path && this.selectedSlide.path.length < 2 && !this.isSelectedSection)
				)
					return [];

				if (this.isSelectedSection) return this.selectedSlide.objectives;

				let parentObjectives = [];
				for (let i = 0; i < this.selectedSlide.path.length - 1; i++) {
					const parentSlide = this.getSlideFromSlideId(this.selectedSlide.path[i].id);
					parentObjectives = parentSlide ? parentObjectives.concat(parentSlide.objectives) : parentObjectives;
				}

				return getUniqueArray(parentObjectives, '_id');
			},
			objectives() {
				let objectives = [];
				this.slideArray.forEach(slide => {
					if (slide.objectives && slide.objectives.length > 0) {
						objectives = objectives.concat(slide.objectives);
					}
				});
				return getUniqueArray(objectives, '_id');
			},
			objectiveCustomIdGlobalVariable() {
				if (!this.selectedSlideObjectives.length) return '';

				return this.selectedSlideObjectives[0].header.metadata.custom_id;
			},
			objectiveTitleGlobalVariable() {
				if (!this.selectedSlideObjectives.length) return '';

				return this.selectedSlideObjectives[0].header.metadata.title;
			},
			objectiveTextGlobalVariable() {
				if (!this.selectedSlideObjectives.length) return '';

				return this.selectedSlideObjectives[0].body.contents.text;
			},
			generalObjective() {
				if (!this.lessonPlanContents.instructionalSettings || !this.lessonPlanContents.instructionalSettings.objective)
					return false;
				return this.lessonPlanContents.instructionalSettings.objective;
			},
			totalTimeLearningObjects() {
				let totalMinutes = 0;
				this.slideArray.forEach(slide => {
					if (slide.type === 'LearningObject' && slide.completionTime) {
						totalMinutes += slide.completionTime;
					}
				});

				return totalMinutes;
			},
			totalTimeLearningObjectsFormatted() {
				let totalMinutesFormatted = '';
				let hours = 0;
				let minutes = 0;
				if (this.totalTimeLearningObjects >= 60) {
					hours = parseInt(this.totalTimeLearningObjects / 60);
					minutes = this.totalTimeLearningObjects % 60;
				} else {
					minutes = this.totalTimeLearningObjects % 60;
				}

				if (hours !== 0) totalMinutesFormatted += `${hours} hour${hours > 1 ? 's' : ''} `;
				if (minutes !== 0) totalMinutesFormatted += `${minutes} minute${minutes > 1 ? 's' : ''}`;
				return totalMinutesFormatted;
			},
			fields() {
				const metadata = vm.value.header.metadata;
				const path = this.selectedSlide ? this.selectedSlide.path : [];
				const slideNumber = this.selectedSlideIndex !== null ? (this.selectedSlideIndex + 1).toString() : 0;

				return [
					{ id: 'content_title', label: metadata.title },
					{ id: 'content_custom_id', label: metadata.custom_id },
					{
						id: 'slide_title',
						label: this.selectedSlide ? this.selectedSlide.title : ''
					},
					{
						id: 'slide_parent_title',
						label: path && path.length > 1 ? path[path.length - 2].title : ''
					},
					{
						id: 'slide_number',
						label: slideNumber
					},
					{ objectives: this.objectives },
					{ breadcrumb: path }
				];
			},
			layouts() {
				return this.lessonPlanContents.layouts
					? this.lessonPlanContents.layouts.filter(layout => layout.imported === false)
					: [];
			},
			importedLayouts() {
				return this.lessonPlanContents.layouts
					? this.lessonPlanContents.layouts.filter(layout => layout.imported !== false)
					: [];
			},
			templates() {
				return this.lessonPlanContents.templates;
			},
			globalVariables() {
				const path = this.selectedSlide ? this.selectedSlide.path : [];
				const slideNumber = this.selectedSlideIndex !== null ? (this.selectedSlideIndex + 1).toString() : 0;
				const selectedSlide = this.selectedSlide;

				return [
					{
						name: 'ContentMetadataCustomId',
						title: 'Custom Id',
						type: 'string',
						value: vm.value.header.metadata.custom_id
					},
					{
						name: 'ContentMetadataTitle',
						title: 'Title',
						type: 'string',
						value: vm.value.header.metadata.title
					},
					{
						name: 'SlideTitle',
						title: 'Slide Title',
						type: 'string',
						value: selectedSlide ? selectedSlide.title : ''
					},
					{
						name: 'SlideNumber',
						title: 'Slide Number',
						type: 'string',
						value: slideNumber
					},
					{
						name: 'SlideParentTitle',
						title: 'Parent Title',
						type: 'string',
						value: path && path.length > 1 ? path[path.length - 2].title : ''
					},
					{
						name: 'SlidePath',
						title: 'Slide Path',
						type: 'array',
						value: path,
						private: true
					},
					{
						name: 'TotalTimeLearningObject',
						title: 'Total Time Learning Object',
						type: 'string',
						value: this.totalTimeLearningObjectsFormatted
					},
					{
						name: 'ObjectiveCustomId',
						title: 'Slide Objective custom id',
						type: 'string',
						value: this.objectiveCustomIdGlobalVariable
					},
					{
						name: 'ObjectiveTitle',
						title: 'Slide Objective Title',
						type: 'string',
						value: this.objectiveTitleGlobalVariable
					},
					{
						name: 'ObjectiveText',
						title: 'Slide Objective Text',
						type: 'string',
						value: this.objectiveTextGlobalVariable
					}
				];
			},
			isViewReviewOrActivityMode() {
				return this.mode === 'activity' || this.mode === 'view' || this.mode === 'review';
			},
			isPreviewMode() {
				return this.getLastPathCurrentUrl() === 'preview';
			},
			isReviewMode() {
				return this.getLastPathCurrentUrl() === 'draft' && this.mode !== 'edit';
			},
			/**
			 * Get navigation availability map
			 * @returns {*[{id, completion, available}]} Navigation map
			 */
			slideNavigationMap() {
				/******* START: Auxiliary methods *******/
				/**
				 * isSomePreviousLocked: returns if there is some locked slide in navigationMap while building (backwards slides)
				 * @param navigationMap
				 * @returns {boolean}
				 */
				const isSomePreviousLocked = navigationMap => {
					let previousLocked = false;
					for (const slide of navigationMap) {
						const slideContent = this.slideArray.find(slideA => slideA.id === slide.id);

						const lockedAndNotCompleted = !slide.available && !slide.completion;
						const slideSingleLockedAndNotCompleted = !slideContent.children && slideContent.locked && !slide.completion;
						if (lockedAndNotCompleted || slideSingleLockedAndNotCompleted) {
							previousLocked = true;
							break;
						}
					}
					return previousLocked;
				};

				/**
				 * isAvailable: returns if current slide is available to be visualized by user
				 * @param previous: previous slide
				 * @param globalLocked: current slide is locked by "general lock"
				 * @param currentSlideLocked: current slide is locked by "slide individual lock"
				 * @param activityState: slide's state array
				 * @param navigationMap: navigation state map
				 * @param slide: current slide
				 * @returns {*|boolean}
				 */
				function isAvailable(previous, globalLocked, currentSlideLocked, activityState, navigationMap, slide) {
					if (!previous) return true; // slide is the first one
					const previousLocked = isSomePreviousLocked(navigationMap);
					if (previous.type === 'ResultsSlideTemplate') {
						//Special case
						return getSlideAvailability(previous, navigationMap);
					} else if (slide.type !== 'ResultsSlideTemplate' && getSlideCompletion(slide, activityState)) {
						// Adaptive Learning exception: avoid to set slide as lock if Adaptive Learning has set slide as completed
						return true;
					} else if (previous.children) {
						// Current is the first element of section/quiz (it can be slide/section)
						if (currentSlideLocked) {
							return getSlideAvailability(previous, navigationMap);
						}
						return previousLocked ? false : getSlideAvailability(previous, navigationMap);
					} else {
						// Current is not the first element of section/quiz
						if (globalLocked) {
							return getSlideAvailability(previous, navigationMap) && getSlideCompletion(previous, activityState);
						} else if (currentSlideLocked) {
							return previousLocked ? false : getSlideAvailability(previous, navigationMap);
						} else {
							return !previousLocked;
						}
					}
				}

				/**
				 * getSlideAvailability: returns available prop of previous slide passed as argument
				 * @param previous
				 * @param navigationMap
				 * @returns {boolean|*}
				 */
				function getSlideAvailability(previous, navigationMap) {
					const previousState = navigationMap.find(slide => slide.id === previous.id);
					return previousState ? previousState.available : false;
				}

				/**
				 * getSlideCompletion: returns completion prop of slide passed as argument
				 * @param slide
				 * @param activityState
				 * @returns {*|undefined}
				 */
				function getSlideCompletion(slide, activityState) {
					if (slide.type === 'ResultsSlideTemplate') {
						return true;
					}
					if (slide.path.length < 2) {
						return activityState ? activityState.activities[slide.id].completion : undefined;
					} else {
						let completionSlide = activityState;
						slide.path.forEach(path => {
							completionSlide = completionSlide.activities[path.id];
						});
						return completionSlide ? completionSlide.completion : undefined;
					}
				}

				/**
				 * Set prop locked to each section's children
				 * @param children
				 */
				function setChildrenLocked(children, type) {
					children.forEach(child => {
						child.locked = type;
						if (child.children && child.children.length > 0) {
							setChildrenLocked(child.children, type);
						}
					});
				}

				/******* END: Auxiliary methods *******/

				const interactivePresentationLocked =
					this.lessonPlanContents.settings.styles.navigation &&
					this.lessonPlanContents.settings.styles.navigation.locked;
				let navigationMap = [];
				if (this.activityState && this.mode !== 'review') {
					let previousSlide = false;
					this.slideArray.forEach(slide => {
						if (slide.locked === true) {
							slide.locked = 'sequential';
						}
						const currentSlideLocked = slide.locked ? !getSlideCompletion(slide, this.activityState) : false;
						const currentSectionLocked = slide.locked && slide.children && slide.children.length > 0;
						if (currentSectionLocked) {
							setChildrenLocked(slide.children, slide.locked);
						}
						navigationMap.push({
							id: slide.id,
							completion: getSlideCompletion(slide, this.activityState),
							available: isAvailable(
								previousSlide,
								interactivePresentationLocked,
								currentSlideLocked,
								this.activityState,
								navigationMap,
								slide
							)
						});
						previousSlide = slide;
					});
				} else {
					this.slideArray.forEach(slide => {
						navigationMap.push({
							id: slide.id,
							completion: false,
							available: true
						});
					});
				}
				return navigationMap;
			},
			lessonPlanObject() {
				return vm.value;
			},
			externalAttachments() {
				return vm.value.body && vm.value.body.contents && vm.value.body.contents.externalAttachments
					? vm.value.body.contents.externalAttachments
					: [];
			}
		},
		watch: {
			view: {
				deep: true,
				handler() {
					this.updateSelectedSlideFromComment();
				}
			},
			'selectedSlide.title'() {
				this.setPathTitle();
			},
			'selectedSlide.skip': {
				immediate: false,
				handler(newValue, oldValue) {
					function skipPropHasChanged(newValue, oldValue) {
						return newValue !== oldValue && newValue !== undefined && oldValue !== undefined;
					}

					if (skipPropHasChanged(newValue, oldValue)) {
						walkTreeData(this.slidesTree.slides, (node, index, parent) => {
							this.addPathToItem(node, parent);
						});
					}
				}
			},
			'globalStyles.block.fontFamily': {
				handler() {
					const defaultFont = this.fontFamilies.find(fontF => fontF.text.includes('Default ('));
					defaultFont.text = `Default (${this.globalStyles.block.fontFamily})`;
				}
			},
			selectedSlide: {
				handler() {
					this.answerHandler = { active: false, data: {} };

					if (
						this.activityState &&
						this.selectedSlide &&
						this.selectedSlide.blocks &&
						this.selectedSlide.blocks.length > 0
					) {
						this.selectedSlide.blocks.forEach(block => {
							if (block.type === 'contentMultipleChoiceQuestion') {
								const slideState = this.getSlideActivityState(this.selectedSlide);
								if (slideState && slideState.response) {
									this.answerHandler = {
										active: true,
										data: {
											response: slideState.response,
											success: slideState.success,
											objCustomId: slideState.objective ? slideState.objective.custom_id : 'NO-OBJ',
											objTitle: slideState.objective ? slideState.objective.title : 'NO OBJECTIVE',
											sections: slideState.objective ? slideState.objective.sections.join(', ') : 'NO SECTIONS'
										}
									};
								}
							}
						});
					}
					this.subtitlesText = false;
				}
			}
		},
		created() {
			if (this.globalStyles.block && this.globalStyles.block.fontFamily) {
				this.fontFamilies[0].text = `Default (${this.globalStyles.block.fontFamily})`;
			}
			this.selectedSlide = this.slideArray[0];
			this.initSlidesTree();
			this.checkTemplates(); // hotfix to recover templates, layouts & galleries properties (if it was deleted for a bug)
			// Copy Learning Object objective to section objective to make it part of current I.P. dependencies
			this.processLearningObjectives();
			this.buildAndCleanLearningObjects(this.mode === 'edit');
			if (this.isViewReviewOrActivityMode) {
				if (!this.activityState) {
					this.initQuizActivities(); // QUIZ: SlideTemplates transformation to Slides (first time it's going to be built)
					this.initActivityState();
				} else {
					this.initQuizActivities(this.activityState); // QUIZ: Slides transformation with activityState (same questions and order as the first time it was built)
				}
			}

			this.updateActivityState(this.activityState);

			this.addExtraFonts();

			if (this.mode === 'edit') this.cleanGarbage();
		},
		methods: {
			...mapActions(STORE_MODULE_ACTIVITY_STATE, {
				updateActivityState: ACTION_UPDATE_ACTIVITY_STATE
			}),
			getLastPathCurrentUrl() {
				return window.location.pathname.split('/').pop();
			},
			checkTemplates() {
				if (!this.lessonPlanContents.layouts) {
					this.$set(this.vm.value.body.contents, 'layouts', []);
				}
				if (!this.lessonPlanContents.templates) {
					this.$set(this.vm.value.body.contents, 'templates', []);
				}
				if (!this.lessonPlanContents.galleries) {
					this.$set(this.vm.value.body.contents, 'galleries', []);
				}
			},
			getSlideFromBlock(blockToFind) {
				for (const slide of this.slideArray) {
					for (const block of slide.blocks) {
						if (block.id === blockToFind.id) {
							return slide;
						}
					}
				}
			},
			getSlideFromSlideId(slideId) {
				for (const slide of this.slideArray) {
					if (slide.id === slideId) {
						return slide;
					}
				}
			},
			getParentIdFromSlide(slideToFind) {
				for (const slide of this.slideArray) {
					if (slide.id === slideToFind.id) {
						// slide.path.length - 2 => the last one is the current slide (length - 1), the previous one is the parent (length - 2)
						if (slide.path.length > 1) {
							return slide.path[slide.path.length - 2].id;
						} else {
							return '$root';
						}
					}
				}
			},
			getSectionForObjective(objectiveId) {
				let sectionId = undefined;
				for (const slide of this.slideArray) {
					if (slide.objectives && slide.objectives.length > 0) {
						if (slide.objectives.find(objective => objective._id === objectiveId)) {
							sectionId = slide.id;
							break;
						}
					}
				}
				return sectionId;
			},
			initSlidesTree() {
				this.slidesTree = new models.SlidesTree({
					slides: this.lessonPlanContents.slides || []
				});
				walkTreeData(this.slidesTree.slides, (node, index, parent) => {
					this.addPathToItem(node, parent);
				});
			},
			buildAndCleanLearningObjects(isEditMode) {
				function addParentIdToPathChildren(slides, parent) {
					slides.forEach(slide => {
						let path = cloneDeep(parent.path);
						if (slide.path) {
							slide.path.unshift(path[0]); //insert LearningObject ID as first position path
						} else {
							slide.path = path;
							slide.path.push({ id: slide.id, title: slide.title });
						}
						if (slide.children && slide.children.length > 0) {
							addParentIdToPathChildren(slide.children, slide);
						}
					});
				}

				function sufixSlideIds(slides, sufix) {
					slides.map(slide => {
						slide.id = `${slide.id}-${sufix}`;
						if (slide.children && slide.children.length) sufixSlideIds(slide.children, sufix);
					});
				}

				function findLearningObjectAndInsertIntoIP(slides) {
					slides.forEach(slide => {
						if (slide.type && slide.type === 'LearningObject') {
							if (!isEditMode) {
								// Build LO childrens for View/Activity/Review mode
								sufixSlideIds(slide.reference.body.contents.slides, slide.id);
								slide.children = slide.reference.body.contents.slides;
								// Add parent path (slide ID) to all Learning Object childrens
								addParentIdToPathChildren(slide.children, slide);
							} else {
								// delete children for "edit mode" #9212
								slide.children = false;
							}
							// Clear unnecessary LO information
							slide.completionTime = slide.reference.body.contents.instructionalSettings
								? slide.reference.body.contents.instructionalSettings.completionTime
								: 0;
							delete slide.reference.body;
						}
						if (slide.children && slide.children.length > 0) {
							findLearningObjectAndInsertIntoIP(slide.children);
						}
					});
				}
				findLearningObjectAndInsertIntoIP(this.lessonPlanContents.slides);
			},
			isSection(slide) {
				return slide ? Array.isArray(slide.children) : false;
			},
			isQuiz(slide) {
				return slide && slide.type ? slide.type === 'QuizSlide' : false;
			},
			isSlide(slide) {
				return slide && slide.type ? slide.type === 'Slide' : !Array.isArray(slide.children);
			},
			isLearningObject(slide) {
				return slide && slide.type ? slide.type === 'LearningObject' : false;
			},
			isQuizChildren(tree, slide) {
				let isQuizChildrenFound = false;

				function isQuizChildrenRec(tree, slideToCheck) {
					for (const node of tree) {
						if (node.type === 'QuizSlide') {
							for (const nodeChildren of node.children) {
								if (nodeChildren.id === slideToCheck.id) {
									isQuizChildrenFound = true;
									break;
								}
							}
						} else if (node.children && node.children.length > 0) {
							isQuizChildrenRec(node.children, slideToCheck);
						}
					}
				}

				isQuizChildrenRec(tree, slide);
				return isQuizChildrenFound;
			},
			hasQuizInIP() {
				for (const slide of this.slideArray) {
					if (slide.type === 'QuizSlide') {
						return true;
					}
				}
				return false;
			},
			setPathTitle() {
				if (this.selectedSlide) {
					const path = this.selectedSlide.path;
					if (path && path.length > 0) {
						path[path.length - 1].title = this.selectedSlide.title;
					}
					this.setPathTitleChildren();
				}
			},
			setPathTitleChildren() {
				if (this.selectedSlide.children) {
					this.selectedSlide.children.forEach(child => {
						if (child.path && child.path[child.path.length - 2]) {
							child.path[child.path.length - 2].title = this.selectedSlide.title;
						}
					});
				}
			},
			selectSlideByIndex(newIndex) {
				if (newIndex < this.slidesVisible.length && newIndex > -1) {
					this.selectedSlide = this.slidesVisible[newIndex];
					this.navigateToRootSlideIfNeeded(this.selectedSlide);
				}
			},
			selectSlideById(id) {
				const nextSlideState = this.slideNavigationMap.find(slide => slide.id === id);
				if (nextSlideState) {
					if (nextSlideState.available) {
						this.selectedSlide = this.slideArray.find(s => s.id === id);
						// Navigation from Quiz: Objectives Level (check if skipped)
						if (this.selectedSlide.skip && this.mode !== 'edit') {
							const slideActivityState = this.getSlideActivityState(this.selectedSlide);
							// if: completion reason is optional => force navigation to show modal (skip / review) // else: navigate to the following one
							if (slideActivityState.completionReason !== 'optional') {
								this.goToNextAvailableSlide();
							}
						}
						this.navigateToRootSlideIfNeeded(this.selectedSlide);
					} else {
						this.notificationHandler = {
							active: true,
							text: "Oops, you can't navigate to selected slide. Please, try to navigate progressively",
							color: 'error'
						};
					}
				} else {
					this.notificationHandler = {
						active: true,
						text: 'Oops, slide does not exists',
						color: 'error'
					};
				}
			},
			showNotification(notificationText, color) {
				this.notificationHandler = {
					active: true,
					text: notificationText,
					color: color ? color : 'error'
				};
			},
			dismissNotification() {
				this.notificationHandler = {
					active: false,
					text: '',
					color: 'error'
				};
			},
			isSectionAvailable(id) {
				const nextSlideState = this.slideNavigationMap.find(slide => slide.id === id);
				if (nextSlideState) {
					return nextSlideState.available;
				} else {
					return false;
				}
			},
			nextSlide() {
				if (this.canGoNext) {
					this.selectSlideByIndex(this.selectedSlideIndex + 1);
				}
			},
			prevSlide() {
				if (this.canGoPrev) {
					this.selectSlideByIndex(this.selectedSlideIndex - 1);
				}
			},
			gotoNextSlideOutAvailable() {
				const selectNextSlideInRootOfSlideId = id => {
					const slideIndex = this.slidesTree.slides.findIndex(slide => slide.id === id);
					const nextSlide = this.slidesTree.slides[slideIndex + 1];
					if (nextSlide) {
						this.selectSlideById(nextSlide.id);
					} else {
						this.selectSlideByIndex(this.slidesVisible.length - 1);
					}
				};

				const searchNextSlide = slide => {
					const parentId = this.getParentIdFromSlide(slide);
					if (parentId === '$root') {
						selectNextSlideInRootOfSlideId(slide.id);
					} else {
						const parentSlide = this.slideArray.find(slide => slide.id === parentId);
						const currentSlideIndex = parentSlide.children.findIndex(child => child.id === this.selectedSlide.id);
						const nextSlide = parentSlide.children[currentSlideIndex + 1];
						if (nextSlide) {
							this.selectSlideById(nextSlide.id);
						} else {
							searchNextSlide(parentSlide);
						}
					}
				};
				searchNextSlide(this.selectedSlide);
			},
			goToNextAvailableSlide() {
				const slideArrayIndex = this.slideArray.findIndex(s => s.id === this.selectedSlide.id);
				let nextAvailableSlide = undefined;
				for (let index = slideArrayIndex; index < this.slideArray.length; index++) {
					if (!this.slideArray[index].skip) {
						nextAvailableSlide = this.slideArray[index];
						break;
					}
				}
				this.selectedSlide = nextAvailableSlide
					? nextAvailableSlide
					: this.slidesVisible[this.slidesVisible.length - 1];
			},
			flat(array) {
				let result = [];
				array.forEach(a => {
					result.push(a);
					if (Array.isArray(a.children)) result = result.concat(this.flat(a.children));
				});
				return result;
			},
			selectLayoutById(id, imported = false) {
				let layout = this.lessonPlanContents.layouts.find(l => l.id === id);
				if (layout) {
					this.selectedSlide = layout.slide;
					this.imported = imported;
				}
			},
			findElementByBookmark(tree, bookmark) {
				let result = {
					found: false
				};
				for (let i = 0; i < tree.length; i++) {
					if (!result.found) {
						if (tree[i].bookmark == bookmark) {
							result = {
								found: true,
								element: tree[i]
							};
						} else if (tree[i].children && tree[i].children.length) {
							result = this.findElementByBookmark(tree[i].children, bookmark);
						}
					}
				}
				return result;
			},
			goToFirstSlide() {
				const slides = this.lessonPlanContents.slides;
				if (slides && slides.length > 0) {
					this.selectSlideById(slides[0].id);
				}
			},
			goToFirstLayout() {
				if (this.layouts.length > 0) {
					this.selectLayoutById(this.layouts[0].id);
				} else if (this.importedLayouts.length > 0) {
					this.selectLayoutById(this.importedLayouts[0].id, true);
				}
			},
			getSlideFromBookmark(bookmark) {
				return this.findElementByBookmark(this.slidesTree.slides, bookmark);
			},
			goToBookmarkTarget(bookmark) {
				const slideElement = this.getSlideFromBookmark(bookmark);
				if (slideElement.element) {
					this.selectSlideById(slideElement.element.id);
				}
			},
			addExtraFonts() {
				const extraFonts = [
					{ name: 'brucepower', font: 'Gotham' },
					{ name: 'entergy', font: 'ProximaNova' }
				];
				extraFonts.forEach(extraFont => {
					if (branding.name === extraFont.name) {
						this.fontFamilies.unshift({ text: extraFont.font, value: extraFont.font });
					}
				});
			},
			getSlideObjectFromId(id) {
				let foundSlide = undefined;
				function searchSlide(slides, id) {
					for (const slide of slides) {
						if (slide.id === id) {
							foundSlide = slide;
							break;
						}
						if (slide.children && slide.children.length > 0) {
							searchSlide(slide.children, id);
						}
					}
				}
				searchSlide(this.lessonPlanContents.slides, id);
				return foundSlide;
			},
			processLearningObjectives() {
				this.slideArray.forEach(slide => {
					if (
						slide.type === 'LearningObject' &&
						slide.reference &&
						slide.reference.body &&
						slide.reference.body.contents.instructionalSettings &&
						slide.reference.body.contents.instructionalSettings.objective
					) {
						slide.objectives = [slide.reference.body.contents.instructionalSettings.objective];
					}
				});
			},
			toggleSlideNumber() {
				this.slideNumberShown = !this.slideNumberShown;
			},
			getParentQuiz(slide) {
				let parentQuiz = false;
				const parentId = this.getParentIdFromSlide(slide);
				if (parentId) {
					const parent = this.getSlideFromSlideId(parentId);
					if (parent && parent.type === 'QuizSlide') {
						parentQuiz = parent;
					}
				}
				return parentQuiz;
			},
			toggleSubtitles() {
				this.subtitlesActive = !this.subtitlesActive;
			},
			setSubtitlesText(text) {
				this.subtitlesText = text;
			},
			selectDefaultLang(lang) {
				this.defaultSubtitles = lang;
			}
		}
	});
}
