import PtApiClient from "plant-api-client"
import comments from "./modules/comments"
import revisions from "./modules/revisions"
import editor from "./modules/editor"
import activityState from './modules/activityState';
import { getContentId, getCustomId, getType } from "../../utils/dbTool"
import {
  STORE_CONTENT_NAME,
  STATE_CAN_EDIT,
  STATE_CURRENT_VIEW,
  STATE_ITEM_REVISION,
  STATE_ITEM_ID,
  STATE_LOADING,
  STATE_REVISION_CHANGED_TOGGLE,
  STATE_SELECTED_TAG,
  STATE_DEPENDENCIES,
  STATE_WEAK_DEPENDENCIES,
  GETTER_ACTIVE_USER,
  MUTATION_DESTROY_CONTENT,
  MUTATION_SELECT_TAG,
  MUTATION_SET_CAN_EDIT,
  MUTATION_SET_COMMENT_ITEM_DRAFT,
  MUTATION_SET_CONTENT,
  MUTATION_SET_CONTENT_REVISION,
  MUTATION_SET_DEPENDENCIES,
  MUTATION_SET_WEAK_DEPENDENCIES,
  MUTATION_SET_CURRENT_VIEW,
  MUTATION_SET_REPLY_ITEM_DRAFT,
  MUTATION_UPDATE_ITEM_REVISION_METADATA,
  ACTION_BUILD_SCORM,
  ACTION_CHECK_DRAFT,
  ACTION_COPY_CONTENT_FROM_APPROVED_REVISION,
  ACTION_COPY_CONTENT_FROM_REVISION,
  ACTION_CREATE_DRAFT_FROM_REVISION,
  ACTION_DELETE_CONTENT,
  ACTION_DELETE_SCORM,
  ACTION_DOWNLOAD_SCORM,
  ACTION_GET_DEPENDENTS,
  ACTION_LOAD_CONTENT,
  ACTION_LOAD_DRAFT,
  ACTION_SET_CONTENT_REVISION,
  ACTION_UPDATE_ITEM_REVISION_METADATA,
  ACTION_CALCULATE_DEPENDENCIES,
  ACTION_PREVIEW_SCORM
} from "@/store_constants/content"
import findRefs from "@/utils/findRefs"

export default {
	name: STORE_CONTENT_NAME,
	namespaced: true,
	state: {
		[STATE_ITEM_REVISION]: null,
		[STATE_ITEM_ID]: null,
		[STATE_CURRENT_VIEW]: null,
		[STATE_REVISION_CHANGED_TOGGLE]: false,
		[STATE_LOADING]: false,
		[STATE_SELECTED_TAG]: false,
		[STATE_CAN_EDIT]: false,
		[STATE_DEPENDENCIES]: null,
		[STATE_WEAK_DEPENDENCIES]: null
	},
	getters: {
		[GETTER_ACTIVE_USER](state, getters, rootState) {
			return rootState.user.profile;
		}
	},
	mutations: {
		[MUTATION_SET_CONTENT](state, content) {
			if (!content.approval_process.comments) {
				// Set empty array for comments to make it reactive when user adds the first comment
				content.approval_process.comments = [];
			}
			state[STATE_ITEM_REVISION] = content;
			state[STATE_LOADING] = false;
			localStorage.latestItemRevisionId = content._id;
		},
		async [MUTATION_SET_DEPENDENCIES](state, dependencies) {
			state[STATE_DEPENDENCIES] = dependencies;
		},
		async [MUTATION_SET_WEAK_DEPENDENCIES](state, weakDependencies) {
			state[STATE_WEAK_DEPENDENCIES] = weakDependencies;
		},
		[MUTATION_SET_CONTENT_REVISION](state, content) {
			state.itemRevision.body.contents = content;
			state[STATE_REVISION_CHANGED_TOGGLE] = !state[STATE_REVISION_CHANGED_TOGGLE];
		},
		[MUTATION_SET_CAN_EDIT](state, editState) {
			state[STATE_CAN_EDIT] = editState;
		},
		[MUTATION_SET_CURRENT_VIEW](state, current_view) {
			state[STATE_CURRENT_VIEW] = current_view;
		},
		[MUTATION_SELECT_TAG](state, tag) {
			state[STATE_SELECTED_TAG] = tag;
		},
		[MUTATION_DESTROY_CONTENT](state) {
			state[STATE_ITEM_REVISION] = false;
			state[STATE_DEPENDENCIES] = false;
			state[STATE_WEAK_DEPENDENCIES] = false;
		},
		[MUTATION_SET_COMMENT_ITEM_DRAFT](state, comments) {
			state[STATE_ITEM_REVISION].approval_process.comments = comments;
		},
		[MUTATION_SET_REPLY_ITEM_DRAFT](state, comment) {
			state[STATE_ITEM_REVISION].approval_process.comments.push(comment);
		},
		[MUTATION_UPDATE_ITEM_REVISION_METADATA](state, metadata) {
			state[STATE_ITEM_REVISION].header.metadata = { ...state[STATE_ITEM_REVISION].header.metadata, ...metadata };
		}
	},
	actions: {
		[ACTION_UPDATE_ITEM_REVISION_METADATA]({ commit, state, dispatch }, { itemRevision, metadata }) {
			return PtApiClient.updateDraftMetadata(getContentId(itemRevision), metadata)
				.then(res => {
					commit(MUTATION_UPDATE_ITEM_REVISION_METADATA, metadata);
					dispatch('notifications/info', { message: 'Properties saved' }, { root: true });
				})
				.catch(e => {
					dispatch(
						'notifications/error',
						{ message: 'Properties were not saved' },
						{
							root: true
						}
					);
				});
		},
		[ACTION_DELETE_CONTENT]({ commit }) {
			commit(MUTATION_DESTROY_CONTENT);
		},
		async [ACTION_LOAD_CONTENT]({ commit, state, dispatch }, { id, major_revision, minor_revision }) {
			if (state[STATE_LOADING]) return;
			state[STATE_LOADING] = true;
			commit(MUTATION_DESTROY_CONTENT);
			try {
				// If there is no revision set, find the latest revision
				if (major_revision == undefined && minor_revision == undefined) {
					var itemAllRevisions = await PtApiClient.getAllRevisionsByContentId(id);
					major_revision = itemAllRevisions[0].header.major_revision;
					minor_revision = itemAllRevisions[0].header.minor_revision;
				}
				var itemRevision = await PtApiClient.getRevisionByContentId(id, major_revision, minor_revision);
				commit(MUTATION_SET_CONTENT, itemRevision);
				dispatch(ACTION_CALCULATE_DEPENDENCIES);
				commit(MUTATION_SET_CAN_EDIT, false);
				commit(MUTATION_SELECT_TAG, getCustomId(itemRevision));
				dispatch('revisions/loadRevisions', getContentId(itemRevision));
				return itemRevision;
			} catch (error) {
				if (error.response && error.response.data) {
					dispatch(
						'notifications/error',
						{ message: error.response.data.message },
						{
							root: true
						}
					);
				} else {
					dispatch(
						'notifications/error',
						{ message: 'Something happened when trying to fetch data' },
						{
							root: true
						}
					);
				}
			}
			return;
		},
		async [ACTION_LOAD_DRAFT]({ commit, dispatch, getters, state }, { item_id, mode }) {
			commit(MUTATION_DESTROY_CONTENT);
			try {
				state[STATE_ITEM_ID] = item_id;
				var itemDraft = await PtApiClient.getDraftByContentId(item_id, mode);
				commit(MUTATION_SET_CONTENT, itemDraft);
				dispatch(ACTION_CALCULATE_DEPENDENCIES);
				const workflow = itemDraft.approval_process.workflow;
				commit(
					MUTATION_SET_CAN_EDIT,
					workflow.contributors.find(contributor => contributor.role === workflow.assigned_to).user_id ==
						getters.activeUser._id && workflow.permissions.includes('edit')
				);
			} catch (error) {
				if (error.response && error.response.data) {
					dispatch(
						'notifications/error',
						{ message: error.response.data.message },
						{
							root: true
						}
					);
				} else {
					dispatch(
						'notifications/error',
						{ message: 'Something happened when trying to fetch data' },
						{
							root: true
						}
					);
				}
			}
			return;
		},
		async [ACTION_CALCULATE_DEPENDENCIES]({ commit, state }) {
			if (state[STATE_ITEM_REVISION]) {
				if (getType(state[STATE_ITEM_REVISION]) === 'lessonPlan') {
					const contentWithoutTemplates = JSON.parse(JSON.stringify(state[STATE_ITEM_REVISION]));
					contentWithoutTemplates.body.contents.templates = [];
					commit(MUTATION_SET_DEPENDENCIES, await findRefs(contentWithoutTemplates.body.contents));
					commit(MUTATION_SET_WEAK_DEPENDENCIES, await findRefs(state[STATE_ITEM_REVISION].body.contents.templates));
				} else {
					commit(MUTATION_SET_DEPENDENCIES, await findRefs(state[STATE_ITEM_REVISION].body.contents));
					commit(MUTATION_SET_WEAK_DEPENDENCIES, false);
				}
			}
		},
		async [ACTION_CREATE_DRAFT_FROM_REVISION]({ commit, state, getters, dispatch }, commentsToImport = 'none') {
			try {
				// create draft
				var draft = await PtApiClient.createDraft(
					getContentId(state[STATE_ITEM_REVISION]),
					state[STATE_ITEM_REVISION]._id,
					commentsToImport
				);
				// change workflow
				await PtApiClient.updateDraftWorkflow(getContentId(state[STATE_ITEM_REVISION]), 'method', 'changeWorkflow', {
					name: 'no_reviewer',
					lightweight: true
				});
				// assign user to author
				await PtApiClient.updateDraftWorkflow(getContentId(state[STATE_ITEM_REVISION]), 'method', 'reassignRole', {
					role: 'author',
					user_id: getters.activeUser._id,
					lightweight: true
				});
				dispatch(
					'notifications/info',
					{ message: 'Created draft from item' },
					{
						root: true
					}
				);
				return draft;
			} catch (e) {
				dispatch(
					'notifications/error',
					{ message: 'Error: Cannot create a draft if is already one draft for this item' },
					{
						root: true
					}
				);
				return e;
			}
		},
		async [ACTION_GET_DEPENDENTS]({ commit, state, getters, dispatch }) {
			try {
				return await PtApiClient.getContentDependents(
					getContentId(state[STATE_ITEM_REVISION]),
					state[STATE_ITEM_REVISION].header.major_revision,
					state[STATE_ITEM_REVISION].header.minor_revision
				);
			} catch (e) {
				dispatch(
					'notifications/error',
					{ message: 'Error: Cannot get dependats for the selected item' },
					{
						root: true
					}
				);
				return e;
			}
		},
		async [ACTION_COPY_CONTENT_FROM_REVISION]({ dispatch }, copyInfo) {
			try {
				let itemDraft = await PtApiClient.createDraftFromItem(
					copyInfo.revisionId,
					copyInfo.custom_id,
					copyInfo.title,
					copyInfo.path_id,
					'guide',
					true
				);
				dispatch('notifications/info', { message: 'Content successfully copied' }, { root: true });
				return itemDraft;
			} catch (error) {
				dispatch(
					'notifications/error',
					{ message: 'Error: Content could not be copied' },
					{
						root: true
					}
				);
				return error;
			}
		},
		async [ACTION_BUILD_SCORM]({ dispatch }, { contentId, majorRev, minorRev, version }) {
			try {
				const downloadURL = PtApiClient.buildScorm(contentId, majorRev, minorRev, version);
				return downloadURL;
			} catch (error) {
				console.log(error);
				dispatch(
					'notifications/error',
					{ message: 'Error: Content could not be built' },
					{
						root: true
					}
				);
				return error;
			}
		},
		async [ACTION_DELETE_SCORM]({ dispatch }, { contentId, majorRev, minorRev, version }) {
			try {
				let response = PtApiClient.deleteScorm(contentId, majorRev, minorRev, version);
				dispatch('notifications/info', { message: 'SCORM successfully deleted' }, { root: true });
				return response;
			} catch (error) {
				console.log(error);
				dispatch(
					'notifications/error',
					{ message: 'Error: SCORM could not be deleted' },
					{
						root: true
					}
				);
				return error;
			}
		},
		async [ACTION_DOWNLOAD_SCORM]({ dispatch }, { contentId, majorRev, minorRev, version }) {
			try {
				const downloadURL = PtApiClient.downloadScorm(contentId, majorRev, minorRev, version);
				return downloadURL;
			} catch (error) {
				console.log(error);
				dispatch(
					'notifications/error',
					{ message: 'Error: Content could not be downloaded' },
					{
						root: true
					}
				);
				return error;
			}
		},
		async [ACTION_PREVIEW_SCORM]({ dispatch }, { contentId, majorRev, minorRev, version }) {
			try {
				const downloadURL = PtApiClient.getScormUnpackUrl(contentId, majorRev, minorRev, version);
				return downloadURL;
			} catch (error) {
				dispatch(
					'notifications/error',
					{ message: 'Error: Content could not be downloaded' },
					{
						root: true
					}
				);
				return error;
			}
		},
		async [ACTION_COPY_CONTENT_FROM_APPROVED_REVISION]({ dispatch }, copyInfo) {
			try {
				let draft = await PtApiClient.createDraftFromApprovedRevision(
					copyInfo.revisionId,
					copyInfo.custom_id,
					copyInfo.title,
					copyInfo.path_id,
					copyInfo.content_revision_type
				);
				dispatch('notifications/info', { message: 'Content successfully copied' }, { root: true });
				return draft;
			} catch (error) {
				dispatch(
					'notifications/error',
					{
						message: `Error: Content could not be copied ${
							error.response && error.response.data && error.response.data.message
								? 'cause: ' + error.response.data.message
								: ''
						}`
					},
					{
						root: true
					}
				);
				throw new Error(error);
			}
		},
		async [ACTION_CHECK_DRAFT]({}, contentId) {
			try {
				let draft = await PtApiClient.getDraftByContentId(contentId, 'no-dereference');
				return draft ? true : false;
			} catch (e) {
				return false;
			}
		},
		async [ACTION_SET_CONTENT_REVISION]({ commit }, content) {
			commit(MUTATION_SET_CONTENT_REVISION, content);
		}
	},
	modules: {
		revisions,
		comments,
		editor,
		activityState
	}
};
