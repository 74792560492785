<template>
  <v-app>
    <loading v-if="loading" />

    <app-loading />

    <v-toolbar
      id="content-main-toolbar"
      height="80px"
      app
      flat
      class="elevation-0 hide-fullscreen"
      style="padding-left: 0px">
      <v-toolbar-items class="hidden-sm-and-down" e2e-id="properties">
        <v-btn flat @click="viewWorkflow()">
          <ContentTypeIcon v-if="itemRevision" :type="getType(itemRevision)" />
        </v-btn>
      </v-toolbar-items>

      <v-divider vertical></v-divider>

      <title-bar-title />

      <v-spacer></v-spacer>

      <div 
        v-if="compressedActivityState && canShowSuspendDataSize" 
        class="mx-2"
      >
        <v-progress-linear
            :value="suspendDataPercentage"
            :color="getSuspendDataProgressColor(suspendDataPercentage)"
        >
        </v-progress-linear>
        <span class="text--secondary">
          {{ Math.round(suspendDataSizeInBytes / 1000) }} kB / 64 kB used
        </span>
      </div>

      <v-btn v-if="canShowPrintButton" color="primary" outline @click="printPDF()">
        <v-icon left>mdi-printer</v-icon>
        Print PDF
      </v-btn>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" v-if="isDraft && canEdit" color="primary" outline @click="openAsViewer()">
            <v-icon left>mdi-eye-outline</v-icon>
            Preview
          </v-btn>
        </template>
        <span>View content like it will look when it is approved</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            v-if="getType(itemRevision) === 'lessonPlan' && !isActivityPreview && !isPreview"
            color="primary"
            outline
            @click="openAsActivity()">
            <v-icon left>mdi-file-check-outline</v-icon>
            Preview Activity
          </v-btn>
        </template>
        <span>View content like it will look when SCORM is generated</span>
      </v-tooltip>

      <title-bar-save v-if="isDraft && canEdit" />

      <v-divider vertical class="ml-4"></v-divider>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-tooltip bottom v-for="drawer in drawers" :key="drawer.title">
          <template v-slot:activator="{ on }" v-if="drawer.showOption">
            <v-btn
              flat
              @click="right_drawer = drawer.title"
              v-on="on"
              :input-value="right_drawer == drawer.title"
              e2e-attr="functionalities">
              <v-badge v-if="drawer.badge" small outline right :color="drawer.badge.color">
                <v-icon v-if="drawer.badge.icon" slot="badge" dark small>{{ drawer.badge.icon }}</v-icon>
                <span v-else-if="drawer.badge.count" slot="badge">{{ drawer.badge.count }}</span>
                <span>
                  <v-icon>{{ drawer.icon }}</v-icon>
                </span>
              </v-badge>
              <span v-else>
                <v-icon>{{ drawer.icon }}</v-icon>
              </span>
            </v-btn>
          </template>
          <span>{{ drawer.title }}</span>
        </v-tooltip>
      </v-toolbar-items>

      <v-divider vertical class="mr-3"></v-divider>

      <workflow-buttons v-if="isDraft" e2e-id="workflow" />

      <div v-else e2e-id="workflow">
        <title-bar-draft v-if="!isActivityPreview && !isPreview" />
      </div>
    </v-toolbar>

    <v-divider></v-divider>

    <v-content>
      <router-view></router-view>
    </v-content>

    <v-navigation-drawer
      v-model="right_drawer"
      app
      right
      width="400px"
      disable-resize-watcher
      disable-route-watcher
      e2e-id="right-drawer">
      <v-toolbar class="elevation-0 transparent" flat>
        <v-icon>{{ selectedDrawer.icon }}</v-icon>
        <v-toolbar-title>{{ selectedDrawer.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="right_drawer = false">mdi-close</v-icon>
      </v-toolbar>

      <v-divider></v-divider>

      <component :is="selectedDrawer.component" />
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import Vue from "vue"
import { ContentTypeIcon, PtModal } from "plant-common"
import ContentTitleBar from "./TitleBar"
import DrawerReferences from "./DrawerReferences"
import DrawerReviewComments from "./DrawerReviewComments"
import DrawerConfigurationControl from "./DrawerConfigurationControl"
import DrawerAttachments from "./DrawerAttachments"
import Loading from "./Loading"
import TitleBarDraft from "./TitleBarDraft"
import TitleBarSave from "./TitleBarSave"
import TitleBarTitle from "./TitleBarTitle"
import WorkflowButtons from "./WorkflowButtons"
import AppLoading from "@/components/app/AppLoading"

import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import {
  STORE_EXPLORER_NAME,
  ACTION_GET_ALL_LIBRARY_FOLDERS,
  STATE_ALL_LIBRARY_FOLDERS
} from "@/store_constants/explorer"
import explorer_store from "@/store/explorer"
import content_store from "@/store/content"
import {
  STORE_CONTENT_NAME,
  STORE_MODULE_COMMENTS,
  STORE_MODULE_EDITOR,
  STATE_ITEM_REVISION,
  STATE_DEPENDENCIES,
  STATE_CAN_EDIT,
  STATE_CURRENT_VIEW,
  STATE_LOADING,
  GETTER_COMMENTS,
  MUTATION_SET_CURRENT_VIEW,
  ACTION_GET_DEPENDENTS,
  ACTION_UPDATE_ITEM_REVISION_METADATA,
  ACTION_UPLOAD_DRAFT,
  ACTION_CALCULATE_DEPENDENCIES,
  STORE_MODULE_ACTIVITY_STATE,
  STATE_COMPRESSED_ACTIVITY_STATE
} from "@/store_constants/content"
import {
  STORE_USER_NAME,
  STATE_PROFILE,
  GETTER_IS_ADMIN,
  GETTER_IS_SUPERVISOR,
  GETTER_IS_STUDENT
} from "@/store_constants/user"
import { getPath, getType } from "../../../utils/dbTool"
import WorkflowTypes from "../../workflow/constants/WorkflowTypes"
import { getSummaryChanges } from "../../../../../plant-common/src/utils"
import { GETTER_IS_INSTRUCTOR } from "../../../store_constants/user"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import _ from "lodash"

export default {
  name: "ContentLayout",
  mixins: [PtModal],
  data() {
    return {
      isDraft: false,
      isPreview: false,
      isActivityPreview: false,
      right_drawer: false,
      workflows: WorkflowTypes,
      countBadgeComments: 0,
      recalculateDependencies: _.debounce(() => {
        this.calculateDependencies()
      }, 1500),
      suspendDataSizeInBytes: 0,
      suspendDataPercentage: 0,
      maxSuspendDataSize: 65536
    }
  },
  beforeCreate() {
    if (this.$store.registerModule(STORE_CONTENT_NAME, content_store) === false) {
      this.$store.unregisterModule(STORE_CONTENT_NAME)
      this.$store.registerModule(STORE_CONTENT_NAME, content_store)
    }

    if (!this.$store.state[STORE_EXPLORER_NAME]) this.$store.registerModule(STORE_EXPLORER_NAME, explorer_store)
  },
  async created() {
    await this.getAllLibraryFolders()
  },
  mounted() {
    let path = this.$router.app.$route.fullPath.split("/")
    const urlPathMode = path[path.length - 1].split("#")[0]
    if (urlPathMode === "draft") this.isDraft = true
    if (urlPathMode === "preview") this.isPreview = true
    if (urlPathMode === "preview-activity") this.isActivityPreview = true
  },
  provide() {
    return {
      DependencyBus: new Vue()
    }
  },
  components: {
    ContentTitleBar,
    ContentTypeIcon,
    Loading,
    TitleBarDraft,
    TitleBarSave,
    TitleBarTitle,
    WorkflowButtons,
    AppLoading
  },
  computed: {
    ...mapState(STORE_MODULE_ACTIVITY_STATE, {
      compressedActivityState: STATE_COMPRESSED_ACTIVITY_STATE
    }),
    ...mapState(STORE_CONTENT_NAME, {
      itemRevision: STATE_ITEM_REVISION,
      dependencies: STATE_DEPENDENCIES,
      current_view: STATE_CURRENT_VIEW,
      loading: STATE_LOADING,
      canEdit: STATE_CAN_EDIT
    }),
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE
    }),
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    }),
    ...mapState(STORE_EXPLORER_NAME, {
      allLibraryFolders: STATE_ALL_LIBRARY_FOLDERS
    }),
    ...mapGetters(STORE_USER_NAME, {
      isAdmin: GETTER_IS_ADMIN,
      isSupervisor: GETTER_IS_SUPERVISOR,
      isInstructor: GETTER_IS_INSTRUCTOR,
      isStudent: GETTER_IS_STUDENT
    }),
    ...mapGetters(STORE_MODULE_COMMENTS, {
      comments: GETTER_COMMENTS
    }),
    selectedDrawer() {
      if (this.right_drawer) {
        return this.drawers.find(d => d.title == this.right_drawer)
      } else {
        return {}
      }
    },
    countExternalAttachments() {
      return this.itemRevision?.body?.contents?.externalAttachments?.length
    },
    hasComments() {
      return this.comments.filter(comment => !comment.deleted_at).length
    },
    colorBadgeComments() {
      // red > orange > green
      const commentsOpenedAndAnswered = this.comments.filter(
        comment =>
          !comment.deleted_at &&
          comment.state === "open" &&
          comment.replies &&
          comment.replies.some(reply => !reply.deleted_at && reply.user_id !== comment.user_id)
      ).length
      const commentsOpened = this.comments.filter(comment => !comment.deleted_at && comment.state === "open").length
      const commentsResolved = this.comments.filter(
        comment => !comment.deleted_at && comment.state === "resolved"
      ).length
      const commentsClosed = this.comments.filter(comment => !comment.deleted_at && comment.state === "closed").length
      if (commentsOpened > 0) {
        this.countBadgeComments = commentsOpened
        return "error"
      } else if (commentsResolved > 0) {
        this.countBadgeComments = commentsResolved
        return "warning"
      } else {
        this.countBadgeComments = commentsClosed
        return "success"
      }
    },
    drawers() {
      return [
        {
          icon: "mdi-link-variant",
          title: "Related Content",
          badge: !this.isStudent
            ? this.allDependenciesLastRevision
              ? { icon: "mdi-check", color: "success" }
              : { icon: "mdi-exclamation-thick", color: "warning" }
            : {},
          showOption: !this.isActivityPreview,
          component: DrawerReferences
        },
        {
          icon: "mdi-comment-multiple-outline",
          title: "Review Comments",
          badge: this.hasComments ? { count: this.countBadgeComments, color: this.colorBadgeComments } : {},
          component: DrawerReviewComments,
          showOption: !this.isActivityPreview && (this.isAdmin || this.isSupervisor || this.isInstructor)
        },
        {
          icon: "mdi-police-badge-outline",
          title: "Configuration Control",
          component: DrawerConfigurationControl,
          showOption: false
        },
        {
          icon: "mdi-paperclip",
          title: "Attachments",
          badge: this.countExternalAttachments ? { count: this.countExternalAttachments, color: "primary" } : {},
          component: DrawerAttachments,
          showOption: this.isDraft && (this.isAdmin || this.isSupervisor || this.isInstructor)
        }
      ]
    },
    allDependenciesLastRevision() {
      if (!this.dependencies) {
        return true
      }
      for (const dependency of this.dependencies) {
        if (!this.isLatestRevision(dependency)) {
          return false
        }
      }
      return true
    },
    isContentApproved() {
      const previewingMode = this.isPreview || this.isActivityPreview
      return !this.canEdit && !this.isDraft && !previewingMode
    },
    isContentInteractiveBook() {
      return this.getType(this.itemRevision) === "interactiveBook"
    },
		isContentInteractiveText() {
			return this.getType(this.itemRevision) === "interactiveText"
		},
		canShowPrintButton() {
			return (this.isContentApproved && !this.isContentInteractiveText) || this.isContentInteractiveBook
		},
    itemPath() {
      return getPath(this.itemRevision, this.allLibraryFolders)
    },
    canShowSuspendDataSize() {
      const urlPath = this.$router.app.$route.fullPath
      const isDraft = urlPath.includes('draft')
      
      if (isDraft && this.canEdit) return true
      return this.isAdmin || this.isSupervisor
    }
  },
  methods: {
    ...mapMutations(STORE_CONTENT_NAME, [MUTATION_SET_CURRENT_VIEW]),
    ...mapActions(STORE_CONTENT_NAME, {
      getDependents: ACTION_GET_DEPENDENTS,
      updateItemRevisionMetadata: ACTION_UPDATE_ITEM_REVISION_METADATA,
      calculateDependencies: ACTION_CALCULATE_DEPENDENCIES
    }),
    ...mapActions(STORE_MODULE_EDITOR, {
      uploadItemDraft: ACTION_UPLOAD_DRAFT
    }),
    ...mapActions(STORE_EXPLORER_NAME, {
      getAllLibraryFolders: ACTION_GET_ALL_LIBRARY_FOLDERS
    }),
    getCurrentRevision(dependency) {
      if (!dependency || !dependency.header) return ""
      return `${dependency.header.major_revision}.${dependency.header.minor_revision}`
    },
    getLatestRevision(dependency) {
      return dependency && dependency.__latest ? dependency.__latest : null
    },
    isLatestRevision(dependency) {
      const latestRevision = this.getLatestRevision(dependency)
      if (!latestRevision) return true
      return this.getCurrentRevision(dependency) === latestRevision
    },
    openAsViewer() {
      window.open(this.$router.currentRoute.fullPath + "/preview", "_blank")
    },
    openAsActivity() {
      let path = this.$router.currentRoute.fullPath.split("#")[0]
      window.open(path + "/preview-activity", "_blank")
    },
    getType: getType,
    printPDF() {
      var cleanRoute = ""
      var pieces = this.$router.currentRoute.path.split("/")
      if (pieces[pieces.length - 1] === "latest") {
        cleanRoute = this.clearRoute(pieces)
      } else if (pieces[pieces.length - 1] === "preview") {
        cleanRoute = pieces.slice(0, pieces.length - 1).join("/") + "/print"
      } else {
        cleanRoute = this.$router.currentRoute.path + "/print"
      }
      window.open(cleanRoute, "_blank")
    },
    clearRoute(pieces) {
      return (
        pieces.slice(0, pieces.length - 1).join("/") +
        "/" +
        this.itemRevision.header.major_revision +
        "/" +
        this.itemRevision.header.minor_revision +
        "/print"
      )
    },
    async viewWorkflow(selectedTab = "") {
      const propsData = {
        title: `Workflow log`,
        approval_process: this.itemRevision.approval_process,
        workflows: this.workflows,
        item: this.itemRevision,
        path: this.itemPath,
        header: this.itemRevision.header,
        settings: this.settings,
        isEdit: this.canEdit,
        isDraft: this.isDraft,
        dependents: this.isDraft ? [] : await this.getDependents()
      }
      if (selectedTab !== "") {
        propsData.selectedTab = selectedTab
      }

      this.$modal("workflow", propsData)
        .then(async metadata => {
          if (!metadata) return

          if (metadata.instructionalSettings) {
            this.itemRevision.body.contents.instructionalSettings = metadata.instructionalSettings
            delete metadata.instructionalSettings
          }

          await this.uploadItemDraft()

          await this.updateItemRevisionMetadata({
            itemRevision: this.itemRevision,
            metadata
          })
        })
        .catch(e => console.log(e))
    },
    getSuspendDataProgressColor(percentage) {
      if (percentage > 75) return 'error'
      if (percentage > 50) return 'warning'
      return 'success'
    }
  },
  watch: {
    "itemRevision._id": {
      immediate: false,
      handler() {
        if (this.isDraft) {
          const workflow = this.itemRevision.approval_process.workflow
          const isAuthor =
            workflow.contributors.find(contributor => contributor.role === workflow.assigned_to).user_id ==
              this.profile._id && workflow.permissions.includes("edit")
          if (!isAuthor && getSummaryChanges(this.itemRevision) != "") {
            this.$modal("workflow", {
              selectedTab: "tab-datasheet-summary",
              title: `Workflow log`,
              approval_process: this.itemRevision.approval_process,
              workflows: WorkflowTypes,
              item: this.itemRevision,
              path: this.itemPath,
              header: this.itemRevision.header,
              settings: this.settings,
              isDraft: this.isDraft,
              isEdit: false,
              dependents: []
            })
          }
        }
      }
    },
    "itemRevision.body.contents": {
      deep: true,
      immediate: false,
      handler() {
        if (!this.isActivityPreview && !this.isPreview && this.canEdit) {
          // dependencies might be updated by editor
          this.recalculateDependencies()
        }
      }
    },
    "compressedActivityState": {
      handler() {
        this.suspendDataSizeInBytes = this.compressedActivityState.length
        this.suspendDataPercentage = (this.suspendDataSizeInBytes / this.maxSuspendDataSize) * 100

        if (this.suspendDataPercentage > 100) this.suspendDataPercentage = 100
      }
    }
  }
}
</script>

<style>
#content-main-toolbar > div.v-toolbar__content {
  padding-left: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
