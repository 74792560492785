/* eslint-disable no-unused-vars */
import Diagram from "../lib/rhojs"
import components from "../lib/masters/src/index"
import beforeInit from "./ptUpdate"
import Vue from "vue"
import Attachment from "../../../classes/Attachment"
import marked from "marked"
import PServiceContent from "../../../services/PServiceContent"
import { getLibraryId } from "../../../utils"
import { DatasheetConfigGroups } from "../../../classes/datasheet/groups"

export default function (vm) {
  let view = false
  if (vm.$props.view) {
    view = vm.$props.view
  }
  return new Vue({
    data() {
      return {
				diagramController: false,
				current_condition_id: '',
				current_condition_changed: false,
				selectedTool: 'point',
				domElement: null,
				pan: null,
				zoom: 1,
				highlighted: [],
				leftPanelSelected: -1,
				rightDrawerStatus: false,
				selected: [],
				datasheet: false,
				relatedNotes: false,
				tag: false,
				datasheetFilter: {},
				datasheetPrefix: '',
				datasheetPrefixExceptionsRegexps: [],
				isContextMenuOpen: false
			};
    },
    created() {
      // Set datasheetFilter (property passed OR default)
      if (this.view && this.view.datasheetFilter) {
        this.datasheetFilter = this.view.datasheetFilter
      } else {
        const SHOW_VALUE = 4
        DatasheetConfigGroups.forEach(group => {
          if (this.filters) {
            this.$set(this.datasheetFilter, group.value, this.filters[group.value])
          } else {
            this.$set(this.datasheetFilter, group.value, SHOW_VALUE)
          }
        })
      }
    },
    methods: {
      init(domElement) {
        this.domElement = domElement
        if (this.svg_attachment) this.refresh()
      },
      refresh() {
        const namespaced_class =
          "pt-svg-" + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        fetch(this.svg_attachment.url)
          .then(r => r.text())
          .then(svg => {
            svg = svg.replace(/<style type="text\/css">([\s\S]*)<\/style>/, (match, css) => {
              const newcss = css.replace(/(\.st[0-9]*)/g, `.${namespaced_class} $1`)
              // eslint-disable-next-line no-useless-escape
              return `<style type="text\/css">${newcss}<\/style>`
            })
            return svg
          })
          .then(data => {
            this.domElement.innerHTML = "nothing"
            this.domElement.innerHTML = data
            this.domElement.classList.add(namespaced_class)
            var svg = this.domElement.querySelector("svg")
            svg.setAttribute("width", "100%")
            svg.setAttribute("height", "100%")
            beforeInit(svg)
            this.diagramController = new Diagram(svg, {
              components,
              zoom: true,
              globalEventListener: async (evt, master, $model) => {
                if (evt.type == "click") {
                  if (evt.currentTarget.dataset.dest) {
                    PServiceContent.openContent(
                      await PServiceContent.findContent({
                        open_custom_id: evt.currentTarget.dataset.dest,
                        library_id: getLibraryId(this.value)
                      }),
                      "tab"
                    )
                  }
                }
                this.$emit(evt.type, { evt, master, $model })
              }
            })
            this.diagramController.panZoom.resize()
            this.diagramController.panZoom.setOnPan(newPan => (this.pan = newPan))
            this.diagramController.panZoom.setOnZoom(newZoom => (this.zoom = newZoom))
            // Attempt to find the diagram datasheet prefix
            try {
              this.datasheetPrefix = svg.querySelector("g > g").dataset.datasheetPrefix || ""
              this.datasheetPrefixExceptionsRegexps = svg.querySelector("g > g").dataset.datasheetPrefixExceptions ? svg.querySelector("g > g").dataset.datasheetPrefixExceptions.split(",").map(prefix => new RegExp(`^${prefix.trim()}`)) : []
            } catch (e) {
              // do nothing if error is thrown
            }
            // Default view: all layers shown (fix visio write display:none as default in some diagrams)
            Object.keys(this.diagramController.layers).forEach(layerName => {
              this.diagramController.layers[layerName].show()
            })
            if (this.view) {
              if (this.view.condition_name) {
                this.changeCondition(this.conditions.find(c => c.name == this.view.condition_name))
              } else if (this.view.condition_id) {
                this.changeCondition(this.conditions.find(c => c._id == this.view.condition_id))
              } else {
                this.changeCondition(this.conditions[0])
              }

              if (this.view.selected) {
                this.selected = this.view.selected
              } else {
                this.selected = []
              }

              if (this.view.highlighted) {
                this.view.highlighted.forEach(e => {
                  let svgComponentHighlighted = document.getElementById(e)
                  this.diagramController.highlight(svgComponentHighlighted)
                })
              }

              if (this.view.layers) {
                Object.keys(this.view.layers).forEach(layerName => {
                  if (this.diagramController.layers.hasOwnProperty(layerName)) {
                    if (!this.view.layers[layerName].visible) {
                      this.diagramController.layers[layerName].hide()
                    }
                    if (this.view.layers[layerName].hasOwnProperty("opacity")) {
                      this.diagramController.layers[layerName].setOpacity(this.view.layers[layerName].opacity)
                    }
                  }
                })
              }
              if (this.view.leftDrawer) {
                // retrocompatibility: we used to save "leftDrawer" as true/false when we want to keep "Diagram Information" left drawer in/active which is now leftPanelSelected = 0
                this.leftPanelSelected = 0
              }
              if (!isNaN(this.view.leftDrawerIndex)) {
                this.leftPanelSelected = this.view.leftDrawerIndex
              }
            } else if (this.conditions.length > 0 && !this.current_condition) {
              this.changeCondition(this.conditions[0])
            }

            this.selected.forEach(selected_id => {
              let svgComponent = document.getElementById(selected_id)
              var id = selected_id
              this.diagramController.select(
                svgComponent,
                () => {
                  var index = this.selected.indexOf(id)
                  if (index > -1) this.selected.splice(index, 1)
                  var tag = svgComponent.dataset.tag
                  this.findDatasheet(tag, id)
                },
                () => {
                  var event = new CustomEvent("diagram-tag-hover", { detail: { target: svgComponent } })
                  document.dispatchEvent(event)
                }
              )
            })
          })
      },
      compareKeys(model, condition, shapes) {
        let keysDefault = []
        for (let idModel of Object.keys(model)) {
          if (!Object.keys(condition).includes(idModel)) {
            // condition does not have component registered => do not assign last model status, set it to status: undefined
            keysDefault[idModel] = { status: undefined }
          }
        }

        for (const [key, value] of Object.entries(keysDefault)) {
          let domElement = document.getElementById(key)
          if (domElement && domElement.dataset && domElement.dataset.master) {
            let master = shapes[domElement.dataset.master]
            if (master && master.model && master.model.status && master.model.status.default) {
              keysDefault[key].status = master.model.status.default
            }
          }
        }
        return keysDefault
      },
      changeCondition(condition, editMode) {
        if (condition) {
          return fetch(condition.url)
            .then(r => r.json())
            .then(data => {
              let model = JSON.parse(JSON.stringify(this.diagramController.model))
              //check ids missed in data
              let setToDefault = this.compareKeys(model, data, components)
              //add rest of components with default status to data
              Object.assign(data, setToDefault)

              this.diagramController.replaceState(data)

              // Open the left drawer when condition is changed but not the first time
              if (this.current_condition_id !== "" && condition._id !== this.current_condition_id) {
                var isEmpty = function (description) {
                  return !description || description == "" || description == "<p></p>"
                }
                if (editMode || !isEmpty(condition.metadata.description)) {
                  // If we are "editing diagram" OR "description is not empty", open left drawer
                  this.leftPanelSelected = 0
                }
              }
              this.zoomReset()
              this.current_condition_id = condition._id
              this.current_condition_changed = false
            })
            .catch(err => {
              console.log(err)
              this.current_condition_id = condition._id
              this.current_condition_changed = false
            })
        }
      },
      zoomIn() {
        this.diagramController.panZoom.zoomIn()
      },
      zoomOut() {
        this.diagramController.panZoom.zoomOut()
      },
      zoomReset() {
        this.diagramController.panZoom.resize()
        this.diagramController.panZoom.fit()
        this.diagramController.panZoom.center()
        this.diagramController.panZoom.reset()
      },
      setElementProp(id, prop, value) {
        this.diagramController.model[id][prop] = value
        this.current_condition_changed = true
        this.isContextMenuOpen = false;
      },
      getElementProp(id, prop) {
        return this.diagramController.model[id][prop]
      },
      saveCurrentConditionMetadata({ title, description }) {
        this.current_condition.metadata = { title, description }
      },
      removeCurrentCondition() {
        this.value.body.attachments.splice(
          this.value.body.attachments.findIndex(a => this.current_condition._id == a._id),
          1
        )
        if (this.conditions.length > 0 && !this.current_condition) {
          this.changeCondition(this.conditions[0])
        }
        this.current_condition_changed = false
      },
      saveCurrentCondition() {
        const file = new File([JSON.stringify(this.diagramController.model)], "condition.json")
        const new_id = Math.random()
        this.current_condition.file = file
        this.current_condition.url = URL.createObjectURL(file)
        this.current_condition_changed = false
        this.current_condition._id = new_id
        this.current_condition_id = new_id
      },
      saveAsNewCondition(title) {
        const file = new File([JSON.stringify(this.diagramController.model)], "condition.json")

        this.attachments.push({
          _id: Math.random(),
          file,
          url: URL.createObjectURL(file),
          metadata: {
            title,
            description: ""
          },
          name: "condition",
          attachment_type: ".json"
        })
        this.changeCondition(this.attachments[this.attachments.length - 1])
        this.current_condition_changed = false
      },
      setVisio(file) {
        var i = this.attachments.findIndex(a => a.name == "vsdx")
        if (i > -1) this.attachments.splice(i, 1)
        this.attachments.push(new Attachment("vsdx", file, URL.createObjectURL(new Blob([file])), "vsdx"))
      },
      setSvg(file) {
        var i = this.attachments.findIndex(a => a.name == "svg")
        if (i > -1) this.attachments.splice(i, 1)
        this.attachments.push(new Attachment("svg", file, URL.createObjectURL(new Blob([file])), "svg"))
        this.refresh()
      },
      toggleRightDrawer() {
        this.rightDrawerStatus = !this.rightDrawerStatus
      },
      highlight(currentTarget) {
        this.diagramController.highlight(currentTarget)
        vm.$emit("update:view", this.getCurrentView())
      },
      getCurrentView() {
        return {
          condition_id: this.current_condition_id,
          highlighted: this.highlighted,
          selected: [...document.querySelectorAll("[data-selectedId]")].map(e => e.dataset.selectedId),
          leftDrawerIndex: this.leftPanelSelected,
          datasheetFilter: this.datasheetFilter,
          layers: this.diagramController.layers
            ? JSON.parse(JSON.stringify(this.diagramController.layers))
            : JSON.parse(JSON.stringify("{}"))
        }
      },
      addNotesToShape(id, notes) {
        if (!this.value.body.contents.shapes[id]) {
          this.$set(this.value.body.contents.shapes, id, {})
        }
        if (!this.value.body.contents.shapes[id].notes) {
          this.$set(this.value.body.contents.shapes[id], "notes", "")
        }
        this.value.body.contents.shapes[id].notes = notes
      },
      addLinkToShape(id, link) {
        if (!this.value.body.contents.shapes[id]) {
          this.$set(this.value.body.contents.shapes, id, {})
        }
        if (!this.value.body.contents.shapes[id].links) {
          this.$set(this.value.body.contents.shapes[id], "links", [])
        }
        this.value.body.contents.shapes[id].links = []
        this.value.body.contents.shapes[id].links.push(link)
      },
      setTag(tag) {
        this.tag = tag
      },
      findDatasheet(tag, id) {
        this.datasheet = false
        // The tag is the datasheetPrefix followed by the component tag, except if matches an exception
        tag = this.datasheetPrefixExceptionsRegexps.find(prefixRegexp => tag.match(prefixRegexp)) ? tag : this.datasheetPrefix + tag

        this.setTag(tag)
        this.$nextTick(() => {
          if (tag) {
            PServiceContent.findContent({ type: "datasheet", custom_id: tag, library_id: getLibraryId(this.value) })
              .then(ds => {
                this.setNotes(false)
                this.datasheet = ds[0]
                if (!this.rightDrawerStatus) this.toggleRightDrawer()
              })
              .catch(() => {
                this.setNotes("No content related")
                this.datasheet = false
                // Close diagramDrawer if it is opened (no related content found)
                if (this.rightDrawerStatus) this.toggleRightDrawer()
              })
          } else if (this.shapes[id]) {
            this.setTag(false)
            if (this.shapes[id].notes) {
              this.notes = marked("##  Notes: \n\n" + this.shapes[id].notes, { sanitize: true })
              this.setNotes(this.notes)
              if (!this.rightDrawerStatus) this.toggleRightDrawer()
            }
          } else {
            this.setNotes("No content related")
            if (!this.rightDrawerStatus) this.toggleRightDrawer()
          }
        })
      },
      setNotes(notes) {
        this.relatedNotes = notes
      },
      async goToLink(id) {
        if (!this.shapes[id]) return
        if (!this.shapes[id].links) return
        if (!this.shapes[id].links[0].target) return
        PServiceContent.openContent(
          await PServiceContent.findContent({
            open_custom_id: this.shapes[id].links[0].target,
            library_id: getLibraryId(this.value),
            isLink: true
          }),
          "tab"
        )
      }
    },
    computed: {
      value() {
        return vm.value
      },
      view() {
        return vm.$props.view
      },
      attachments() {
        return vm.value.body.attachments
      },
      settings() {
        if (!this.value.body.contents.settings) this.$set(this.value.body.contents, "settings", {
          datasheet: {
            defaultView: "table"
          }
        })

        return this.value.body.contents.settings
      },
      shapes() {
        if (!this.value.body.contents) {
          this.$set(this.value.body, "contents", {})
        }
        if (!this.value.body.contents.shapes) {
          this.$set(this.value.body.contents, "shapes", {})
        }
        return this.value.body.contents.shapes
      },
      svg_attachment() {
        return this.value.body.attachments.find(e => e.name == "svg")
      },
      vsdx_attachment() {
        return this.value.body.attachments.find(e => e.name == "vsdx")
      },
      conditions() {
        return this.value.body.attachments.filter(e => e.attachment_type == ".json")
      },
      current_condition() {
        return this.value.body.attachments.find(e => e._id == this.current_condition_id)
      },
      layers() {
        return this.diagramController.layers
      }
    },
    watch: {
      view: {
        immediate: false,
        handler() {
          // Watcher done for "reset pin view" functionallity. It's not working for: selected, datasheetFilter & layers
          if (this.current_condition_id !== this.view.condition_id) this.changeCondition(this.conditions.find(c => c._id == this.view.condition_id))
          if (this.highlighted !== this.view.highlighted && this.view.highlighted) {
            this.view.highlighted.forEach(e => {
              let svgComponentHighlighted = document.getElementById(e)
              this.diagramController.highlight(svgComponentHighlighted)
            })
            this.highlighted = this.view.highlighted
          }
          if (this.leftPanelSelected !== this.view.leftDrawerIndex) this.leftPanelSelected = this.view.leftDrawerIndex
        }
      },
      leftPanelSelected() {
        vm.$emit("update:view", this.getCurrentView())
        setTimeout(() => this.zoomReset(), 100)
      },
      rightDrawerStatus: {
        handler() {
          setTimeout(() => this.zoomReset(), 100)
        }
      },
      current_condition_id: {
        handler() {
          vm.$emit("update:view", this.getCurrentView())
        }
      },
      selected: {
        handler() {
          vm.$emit("update:view", this.getCurrentView())
        }
      },
      highlighted: {
        handler() {
          vm.$emit("update:view", this.getCurrentView())
        }
      },
      layers: {
        deep: true,
        handler() {
          vm.$emit("update:view", this.getCurrentView())
        }
      },
      datasheetFilter: {
        deep: true,
        immediate: true,
        handler() {
          if (this.diagramController) {
            vm.$emit("update:view", this.getCurrentView())
          }
        }
      }
    }
  })
}
