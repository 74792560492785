import { getObjective, getCustomId, getTitle } from "../../../../utils/dbTool"
import AdaptiveLearning from "./AdaptiveLearning"
import Performance from "./Performance"

// Color constants
const ANSWERED = "#daa520"
const RIGHT = "green"
const WRONG = "red"
const VIEWED = "#1976d2"
const NOT_VIEWED = "#cbcbcb"
const OPTIONAL = "#afcffa"

// Completed Reasons
const REASON_OPTIONAL = "optional"
const REASON_NOT_VIEWED = null
const REASON_REVIEWED = "reviewed"
const REASON_SKIPPED = "skipped"
const REASON_VIEWED = "viewed"
const REASON_SUBMITTED = "submitted"

// Slide Icon/Color Map
const mapSlideIcon = {
  completed: {
    optional: {
      icon: "mdi-circle-slice-8",
      color: OPTIONAL,
      tooltip: "Optional Slide"
    },
    viewed: {
      icon: "mdi-circle-slice-8",
      color: VIEWED
    },
    answered: {
      quizNotFinished: {
        icon: "mdi-asterisk-circle-outline",
        color: ANSWERED,
        tooltip: "Question completed, you can change your answer"
      },
      quizFinished: {
        correct: {
          icon: "mdi-check-circle",
          color: RIGHT
        },
        incorrect: {
          icon: "mdi-close-circle",
          color: WRONG
        }
      }
    }
  },
  notCompleted: {
    locked: {
      icon: "mdi-lock-outline",
      color: NOT_VIEWED,
      tooltip: "Slide locked, you should complete the previous one"
    },
    notLocked: {
      optional: {
        icon: "mdi-circle-outline",
        color: OPTIONAL,
        tooltip: "Optional Slide"
      },
      notOptional: {
        icon: "mdi-circle-outline",
        color: NOT_VIEWED,
      }
    }
  }
}

export default {
  mixins: [AdaptiveLearning, Performance],
  computed: {
    isActivityMode() {
      return this.mode === "activity"
    },
    progress() {
      if (!this.slidesTree) return 0
      return this.calculateProgressSection(this.slidesTree.slides)
    },
    isSectionOptional() {
      const activityStateForSlide = this.getSlideActivityState(this.selectedSlide)
      return activityStateForSlide.completionReason === REASON_OPTIONAL
    }
  },
  watch: {
    "selectedSlide.blocks": {
      deep: true,
      handler() {
        this.updateSlideCompletion()
      }
    },
    activityState: {
      deep: true,
      immediate: true,
      handler() {
        if (this.progress) {
          this.activityState.progress = Math.round(this.progress)
        }
        this.vm.$emit("update:activityState", this.activityState)
      }
    }
  },
  methods: {
    initActivityState() {
      // Create activity state always, it can be reorganized in edit mode
      // at any moment when dragging & drop
      this.activityState = {
        completion: false,
        success: undefined,
        activities: {},
        performance: {},
        progress: 0
      }
      this.slidesTree.slides.forEach(slide => {
        this.$set(this.activityState.activities, slide.id, {
          completion: false,
          completionReason: REASON_NOT_VIEWED,
          success: undefined,
          ...(this.hasActivities(slide) ? { activities: this.getActivities(slide) } : {}),
          ...(this.isQuizSlide(slide) ? { score: this.initQuizScore(slide) } : {}),
          blocks: this.initBlocksCompletion(slide)
        })
        if (this.isQuizSlide(slide)) {
          this.initPerformanceForQuiz(slide)
        }
      })
    },
    hasActivities(item) {
      return item.children.length > 0
    },
    isQuizSlide(item) {
      return item.type == "QuizSlide"
    },
    // return the score object for Quiz state
    initQuizScore(quiz) {
      const activities = this.getActivities(quiz)
      let max = 0
      for (const [key, activity] of Object.entries(activities)) {
        max = max + activity.score.max
      }
      return {
        raw: 0,
        min: 0,
        max: max,
        scaled: 0
      }
    },
    // return the score object for slide state
    getActivityScore(activities, activity_id) {
      const activity = activities.find(act => act.slide_id == activity_id)
      if (activity) {
        return {
          raw: 0,
          min: 0,
          max: activity ? activity.points : 1,
          scaled: 0
        }
      } else {
        return {}
      }
    },
    getActivities(item) {
      const activities = {}

      if (item.children.length > 0) {
        this.getActivitiesSection(activities, item)
      } else if (item.blocks.length > 0) {
        this.getActivitiesSlide(activities, item)
      }

      return activities
    },
    initBlocksCompletion(slide) {
      let blocks = {}
      if (slide.blocks && slide.blocks.length > 0) {
        slide.blocks.forEach(block => {
          this.$set(blocks, block.id, {
            completion: this.calculateBlocksCompletion([block], true),
            type: block.type
          })
        })
      }
      return blocks
    },
    getActivitiesSection(activities, item) {
      const searchSectionForObjective = objectiveId => {
        const slidesFiltered = this.slideArray.filter(slide => slide.objectives.find(obj => obj._id === objectiveId))
        return slidesFiltered.map(slide => slide.title)
      }

      item.children.forEach(child => {
        const multipleChoiceQuestion = child.blocks.find(block => block.type == "contentMultipleChoiceQuestion")
        if (child.type != "ResultsSlideTemplate") {
          activities[child.id] = {
            completion: false,
            completionReason: REASON_NOT_VIEWED,
            success: undefined,
            ...(this.hasActivities(child) ? { activities: this.getActivities(child) } : {}),
            ...(item.slideProperties && item.slideProperties.activities
              ? { score: this.getActivityScore(item.slideProperties.activities, child.id) }
              : {}),
            ...(this.isQuizSlide(child) ? { score: this.initQuizScore(child) } : {}),
            blocks: this.initBlocksCompletion(child),

            objective:
              multipleChoiceQuestion && getObjective(multipleChoiceQuestion.data.content_ref)
                ? {
                  custom_id: getCustomId(getObjective(multipleChoiceQuestion.data.content_ref)),
                  _id: getObjective(multipleChoiceQuestion.data.content_ref)._id,
                  title: getTitle(getObjective(multipleChoiceQuestion.data.content_ref)),
                  sections: searchSectionForObjective(getObjective(multipleChoiceQuestion.data.content_ref)._id)
                }
                : undefined
          }
        }
        if (this.isQuizSlide(child)) {
          this.initPerformanceForQuiz(child)
        }
      })
    },
    getActivitiesSlide(activities, item) {
      item.blocks.forEach(block => {
        activities[block.id] = {
          completion: false,
          success: undefined
        }
      })
    },
    calculateProgressSection(item, parent) {
      if (!this.isActivityMode) return 0
      let items = this.flat(item)
      items = items.filter(item => item.type != "ResultsSlideTemplate") // do not add Results Slide as part of Quiz state
      const totalSlides = this.getTotalSlides(items)
      const total = totalSlides.length
      const completedSlides = this.getCompletedSlides(totalSlides).length
      const progress = this.calculateProgress(completedSlides, total)

      if (progress === 100) {
        this.updateActivitySection(parent)
      }

      return progress
    },
    calculateProgress(completed, total) {
      return total > 0 ? (completed / total) * 100 : 0
    },
    getIconColorForSlide(item) {
      let iconColorSlide = false
      const activityState = this.getSlideActivityState(item)
      const isCompleted = activityState && activityState.completion
      const isOptional = activityState.completionReason === REASON_OPTIONAL
      if (!isCompleted) {
        const slideState = this.slideNavigationMap.find(slide => slide.id === item.id)
        const isLocked = slideState && slideState.available === false
        if (isLocked) {
          iconColorSlide = mapSlideIcon.notCompleted.locked
        } else if (isOptional) {
          iconColorSlide = mapSlideIcon.notCompleted.notLocked.optional
        } else {
          iconColorSlide = mapSlideIcon.notCompleted.notLocked.notOptional
        }
      } else {
        if (isOptional) {
          iconColorSlide = mapSlideIcon.completed.optional
        } else {
          const parentQuiz = this.getParentQuiz(item)
          if (parentQuiz) {
            const quizFinished = this.isQuizSubmitted(parentQuiz)
            const activityState = this.getSlideActivityState(item)
            const success = activityState ? activityState.success : undefined
            if (!quizFinished && (success === false || success === true)) {
              iconColorSlide = mapSlideIcon.completed.answered.quizNotFinished
            }
            else if (quizFinished && success === false) {
              iconColorSlide = mapSlideIcon.completed.answered.quizFinished.incorrect
            }
            else if (quizFinished && success === true) {
              iconColorSlide = mapSlideIcon.completed.answered.quizFinished.correct
            } else {
              iconColorSlide = mapSlideIcon.completed.viewed
            }
          } else {
            iconColorSlide = mapSlideIcon.completed.viewed
          }
        }
      }
      return iconColorSlide
    },
    getColorSection(item) {
      if (!this.isActivityMode) return 0
      let items = this.flat(item.children)
      items = items.filter(item => item.type !== "ResultsSlideTemplate")
      const itemsContent = this.getTotalSlides(items)
      const total = itemsContent.length
      if (item.type === "Section" || item.type === "LearningObject") {
        return VIEWED
      } else {
        // Section is a Quiz
        if (this.isQuizSubmitted(item)) {
          const successScore =
            item.slideProperties && item.slideProperties.settings && item.slideProperties.settings.success_score
              ? parseInt(item.slideProperties.settings.success_score)
              : 100
          const quizResponses = this.getSuccessSlidesContent(itemsContent)
          if ((quizResponses.succeded / total) * 100 >= successScore) {
            return RIGHT
          } else {
            return WRONG
          }
        } else {
          return VIEWED
        }
      }
    },
    isQuizFinished(item) {
      let items = this.flat(item.children)
      items = items.filter(item => item.type !== "ResultsSlideTemplate")
      const itemsContent = this.getTotalSlides(items);
      const quizResponses = this.getSuccessSlidesContent(itemsContent)

      return quizResponses.not_answered === 0
    },
    isQuizSubmitted(item) {
      const activityState = this.getSlideActivityState(item)
      return activityState.completionReason === REASON_SUBMITTED
    },
    submitQuiz(quiz) {
      if (this.isQuizFinished(quiz)) {
        let activityState = this.getSlideActivityState(quiz)
        if (this.isQuizSubmitted(quiz)) {
          this.showNotification("Quiz is already submitted, you can review it now")
        } else {
          this.showNotification("Correcting the questions...", "primary")
          let activityStateForActivity = false
          const activities = quiz.children.filter(child => child.type !== "ResultsSlideTemplate")
          activities.forEach(activity => {
            activityStateForActivity = this.getSlideActivityState(activity)
            this.updatePerformanceForQuiz(activity, activityStateForActivity.success)
          })
          const partialScore = this.getPartialScore(quiz.id)
          this.completeAdaptiveLearningSections(
            partialScore.value,
            parseInt(quiz.slideProperties.settings.success_score)
          )
          setTimeout(() => {
            activityState.completionReason = REASON_SUBMITTED
            this.dismissNotification()
          }, Math.floor(Math.random() * (2000 - 500 + 1) + 500));
        }
      } else {
        this.showNotification("Quiz is not finished, please answer all the questions before submitting them")
      }
    },

    skipSection() {
      this.completeSlideWithReason(this.selectedSlide, REASON_SKIPPED, true)
      this.gotoNextSlideOutAvailable()
    },

    reviewSection() {
      this.completeSlideWithReason(this.selectedSlide, REASON_SKIPPED, true)
      this.goToNextAvailableSlide()
    },

    getTotalSlides(slides) {
      return slides.filter(slide => !this.isSection(slide))
    },

    getCompletedSlides(slides) {
      return slides.filter(slide => {
        const activityStateSlide = this.getSlideActivityState(slide)
        return activityStateSlide && activityStateSlide.completion === true
      })
    },
    // return an object with quiz activities states
    getSuccessSlidesContent(arrayItemsContent) {
      const succeded = arrayItemsContent.filter(item => {
        const activityState = this.getSlideActivityState(item)
        return activityState && activityState.success === true
      })
      const failed = arrayItemsContent.filter(item => {
        const activityState = this.getSlideActivityState(item)
        return activityState && activityState.success === false
      })
      const not_answered = arrayItemsContent.filter(item => {
        const activityState = this.getSlideActivityState(item)
        return activityState && activityState.success === undefined
      })
      return {
        succeded: succeded.length,
        failed: failed.length,
        not_answered: not_answered.length
      }
    },

    getIntersectTwoArrays(array1, array2) {
      return array1.filter(value => array2.includes(value))
    },
    // calculate activity score and Quiz score when user submit an activity
    updateActivityResponse(slide, value, response) {
      const searchRecursive = (activityState, slide, value, response) => {
        if (activityState.score) activityState.score.raw = 0
        for (var [key, activity] of Object.entries(activityState.activities)) {
          if (activity.score) {
            if (key == slide.id) {
              if (value) {
                activity.score.raw = activity.score.max
                activity.score.scaled = activity.score.raw / activity.score.max
              } else {
                activity.score.raw = activity.score.min
                activity.score.scaled = activity.score.min / activity.score.max
              }
              this.$set(activity, "success", value)
              this.$set(activity, "response", response)
              const slideState = this.getSlideActivityState(this.selectedSlide)
              if (slideState && slideState.response) {
                this.answerHandler = {
                  active: true,
                  data: {
                    response: slideState.response,
                    success: slideState.success,
                    objCustomId: slideState.objective ? slideState.objective.custom_id : "NO-OBJ",
                    objTitle: slideState.objective ? slideState.objective.title : "NO OBJECTIVE",
                    sections: slideState.objective ? slideState.objective.sections.join(", ") : "NO SECTIONS"
                  }
                }
              }
            }
            if (activityState.score && activity.completion) {
              activityState.score.raw = activityState.score.raw + activity.score.raw
            }
          }
          if (activity.activities) {
            searchRecursive(activity, slide, value, response)
          }
        }
        if (activityState.score) {
          activityState.score.scaled = activityState.score.raw / activityState.score.max
          document.dispatchEvent(new CustomEvent('goToFollowingSlide', { detail: slide }));
        }
      }

      searchRecursive(this.activityState, slide, value, response)
    },
    // return quiz score
    getQuizScore(quizId) {
      let quizScore = 0

      function searchRecursive(activityState, quizId) {
        for (var [key, activity] of Object.entries(activityState.activities)) {
          if (key == quizId) {
            quizScore = activity.score.scaled
            return
          }
          if (activity.activities) {
            searchRecursive(activity, quizId)
          }
        }
      }

      searchRecursive(this.activityState, quizId)
      return quizScore
    },
    getPartialScore(quizId) {
      let partialScore = []
      let partialScoreHuman = "Results per objective: <br><br>"

      const buildPartialScore = activities => {
        let objectiveArray = []
        let objectiveId = false
        for (var [key, activity] of Object.entries(activities)) {
          objectiveId = activity.objective ? activity.objective._id : "none"
          if (!objectiveArray.includes(objectiveId)) {
            objectiveArray.push(objectiveId)
          }
        }

        let activitiesObjective = []
        objectiveArray.forEach(objId => {
          activitiesObjective = Object.values(activities).filter(activity =>
            activity.objective ? activity.objective._id == objId : objId == "none"
          )
          partialScore.push({
            objectiveId: objId,
            title: activitiesObjective[0].objective ? activitiesObjective[0].objective.title : "No objective",
            custom_id: activitiesObjective[0].objective ? activitiesObjective[0].objective.custom_id : "N/A",
            score: `${Math.round(
              (activitiesObjective.reduce((a, b) => a + (b.score.scaled || 0), 0) / activitiesObjective.length +
                Number.EPSILON) *
              100
            )}`,
            scoreValue: activitiesObjective.reduce((a, b) => a + (b.score.scaled || 0), 0) / activitiesObjective.length,
            sections: activitiesObjective[0].objective ? activitiesObjective[0].objective.sections.join(", ") : "N/A",
            activities: `${activitiesObjective.reduce((a, b) => a + (b.score.scaled || 0), 0)}/${activitiesObjective.length}`
          })
        })

        const objectiveSorted = this.objectives.map(obj => getCustomId(obj))
        partialScore.sort(function (a, b) {
          return objectiveSorted.indexOf(a.custom_id) - objectiveSorted.indexOf(b.custom_id)
        })

        for (const objectiveScore of partialScore) {
          partialScoreHuman =
            partialScoreHuman +
            `<b>${objectiveScore.custom_id}</b> ${objectiveScore.title}: <b>${objectiveScore.score}% (${objectiveScore.activities})</b> [Sections: ${objectiveScore.sections}] <br>`
        }
      }

      function searchRecursive(activityState, quizId) {
        for (var [key, activity] of Object.entries(activityState.activities)) {
          if (key === quizId) {
            buildPartialScore(activity.activities)
            return
          }
          if (activity.activities) {
            searchRecursive(activity, quizId)
          }
        }
      }

      searchRecursive(this.activityState, quizId)
      return { partialScoreHuman: partialScoreHuman, value: partialScore }
    },
    completeAdaptiveLearningSections(partialScoreValue, successScore) {
      let objectivesCompleted = []
      partialScoreValue.forEach(value => {
        if ((value.scoreValue * 100) >= successScore) {
          objectivesCompleted.push(value.objectiveId)
        }
      })

      let sectionsObjectiveArray = []
      this.slideArray.forEach(slide => {
        if (slide.objectives && slide.objectives.length > 0) {
          sectionsObjectiveArray.push(slide)
        }
      })

      sectionsObjectiveArray.forEach(section => {
        let objectivesIds = section.objectives.map(objective => objective._id)
        const containsAll = objectivesIds.every(objId => {
          return objectivesCompleted.includes(objId)
        })
        if (containsAll) {
          this.completeSlideWithReason(section, REASON_OPTIONAL, false)
        }
      })
    },

    completeSlideWithReason(slide, reason, completion) {
      let activityState = this.getSlideActivityState(slide)
      const mustUpdateReason = (!activityState.completionReason && reason === REASON_OPTIONAL) || reason !== REASON_OPTIONAL
      if (activityState) {
        if (mustUpdateReason) this.$set(activityState, "completionReason", reason)
        if (completion) {
          activityState.completion = completion
          for (const [key, value] of Object.entries(activityState.blocks)) {
            activityState.blocks[key].completion = completion
          }
        }
      }
      if (slide.children && slide.children.length > 0) {
        slide.children.forEach(children => {
          this.completeSlideWithReason(children, REASON_OPTIONAL, completion)
        })
      }
    },
    blockHasClickContentAction(block) {
      const navigationActions = ["link", "event", "close"]
      const blockActionNoNav =
        block.actions &&
        block.actions.length > 0 &&
        block.actions.find(action => action.trigger === "click" && !navigationActions.includes(action.type))
      const blockDataActionNoNav =
        block.data &&
        block.data.actions &&
        block.data.actions.length > 0 &&
        block.data.actions.find(action => action.trigger === "click" && !navigationActions.includes(action.type))
      return blockActionNoNav || blockDataActionNoNav
    },
    calculateBlocksCompletion(blocks, blockCompletion) {
      blocks.forEach(block => {
        switch (block.type) {
          case "button":
            if (!block.data.visited && this.blockHasClickContentAction(block)) {
              blockCompletion = false
            }
            break
          case "tabs":
          case "carousel":
          case "objectives":
            const dataVariable = block.type === "tabs" ? "tabs" : (block.type === "carousel" ? "cards" : "objectives")
            if (block.data[dataVariable]) {
              block.data[dataVariable].forEach(children => {
                if (!children.visited) {
                  blockCompletion = false
                }
                if (children.blocks && children.blocks.length > 0) {
                  blockCompletion = blockCompletion
                    ? this.calculateBlocksCompletion(children.blocks, blockCompletion)
                    : false
                }
              })
            } else {
              // data.objectives is not set, need to calculate it when rendering the component (retrocompatibility)
              blockCompletion = false
            }
            break
          case "flipCard":
            if (!block.data.visited) {
              blockCompletion = false
            }
            break
          default:
            // do nothing, block is not interactive
            break
        }
        if (block.actions && block.actions.length > 0) {
          block.actions.forEach(action => {
            if (action.blocks && action.blocks.length > 0) {
              blockCompletion = blockCompletion ? this.calculateBlocksCompletion(action.blocks, blockCompletion) : false
            }
          })
        }
      })
      return blockCompletion
    },
    updateSlideCompletion() {
      if (this.isViewReviewOrActivityMode && this.isSelectedSlideContent) {
        let activityState = this.getSlideActivityState(this.selectedSlide)
        if (activityState) {
          activityState.completion = this.getBlocksCompletion(this.selectedSlide.blocks, activityState)
          if (activityState.completion) {
            this.setSlideCompletionReason(activityState)
          }
        }
      }
    },
    getBlocksCompletion(blocks, activitySlide) {
      let slideCompletion = true
      blocks.forEach(block => {
        // Si el block.completion del activityState está seteado a true, predomina sobre el cálculo del estado del bloque
        // (el activityState viene dado del estado de la Interactive Presentation desde el LMS, el estudiante puede haber completado el bloque en otra ocasión que abriera la Interactive Presentation)
        activitySlide.blocks[block.id].completion = activitySlide.blocks[block.id].completion
          ? activitySlide.blocks[block.id].completion
          : this.calculateBlocksCompletion([block], true)
        if (!activitySlide.blocks[block.id].completion) {
          slideCompletion = false
        }
      })
      return slideCompletion
    },
    getSlideActivityState(slide) {
      if (this.activityState) {
        let activityState = this.activityState.activities
        if (slide && slide.path) {
          const path = slide.path.map(el => el.id)
          path.forEach((id, index) => {
            activityState = index < path.length - 1 ? activityState[id].activities : activityState[id]
          })
        }
        return activityState
      } else {
        return undefined
      }
    },
    getRootActivityState(slide) {
      if (this.activityState && slide && slide.path && slide.path.length > 1) {
        let rootSlide = this.getSlideFromSlideId(slide.path[0].id)
        return this.getSlideActivityState(rootSlide)
      } else {
        return false
      }
    },
    needToLoadRootSection(slide) {
      const rootActivityState = this.getRootActivityState(slide)
      return rootActivityState && rootActivityState.completionReason === "optional"
    },
    navigateToRootSlideIfNeeded(slide) {
      if (this.needToLoadRootSection(slide)) {
        this.selectedSlide = this.slideArray.find(s => s.id === slide.path[0].id)
      }
    },
    updateActivityStateSlideOrSectionSuccess(activitySlide) {
      const totalSuccess = []
      for (const id in activitySlide.activities) {
        totalSuccess.push(activitySlide.activities[id].success)
      }

      activitySlide.success = undefined
      if (totalSuccess.includes(false)) {
        activitySlide.success = false
      } else if (totalSuccess.includes(true)) {
        activitySlide.success = true
      }
    },
    updateActivitySection(section) {
      let activityState = section ? this.getSlideActivityState(section) : this.activityState

      this.updateActivitySectionCompletion(activityState)
      this.updateActivityStateSlideOrSectionSuccess(activityState)
    },
    updateActivitySectionCompletion(activityState) {
      activityState.completion = true
      this.setSectionCompletionReason(activityState)
    },
    setSectionCompletionReason(activityState) {
      // null => VIEWED
      // viewed => nothin
      // optional => nothin
      // skipped => REVIEWED
      // reviewed => nothin
      switch (activityState.completionReason) {
        case REASON_SKIPPED:
          activityState.completionReason = this.calculateSectionReasonCompletion(activityState)
          break;
        case REASON_NOT_VIEWED:
          activityState.completionReason = REASON_VIEWED
          break;
        default:
          // do nothing
          break;
      }
    },
    setSlideCompletionReason(activityState) {
      // null => VIEWED
      // viewed => nothin
      // optional => REVIEWED
      // skipped (no se puede dar para una slide)
      // reviewed => nothin
      switch (activityState.completionReason) {
        case REASON_OPTIONAL:
          activityState.completionReason = REASON_REVIEWED
          break;
        case REASON_NOT_VIEWED:
          activityState.completionReason = REASON_VIEWED
          break;
        default:
          // do nothing
          break;
      }
    },
    /**
     * 
     * @param {*} activityState 
     * @returns REASON_REVIEWED if all the slides completionReason is REASON_REVIEWED || REASON_SKIPPED if some slides are not reviewed yet
     */
    calculateSectionReasonCompletion(activityState) {
      let sectionCompletion = REASON_REVIEWED
      const searchRecursive = (activityState) => {
        for (var [key, activity] of Object.entries(activityState.activities)) {
          if (activity.completionReason !== REASON_REVIEWED && activity.completionReason !== REASON_VIEWED) {
            sectionCompletion = REASON_SKIPPED
          }
          if (activity.activities) {
            searchRecursive(activity)
          }
        }
      }
      searchRecursive(activityState)
      return sectionCompletion
    }
  }
}
