// grupo de claves a intercambiar
export default {
  "completion": "c",
  "completionReason": "r",
  "activities": "a",
  "performance": "P",
  "progress": "p",
  "blocks": "b",
  "type": "t",
  "objective": "o",
  "quizzes": "q",
  "section": "s",
  "custom_id": "u",
  "description": "d",
  "id": "i",
  "_id": "I",
  "title": "T",
  "currentLevel": "l",
  "isCorrect": "C",
  "points": "x",
  "question": "e",
  "content_revision_type": "f",
  "text": "z",
  "score": "g",
  "max": "M",
  "min": "m",
  "raw": "R",
  "scaled": "h",
  "sections": "j",
  "response": "k",
  "correctAnswer": "v",
  "explanation": "n",
  "userChoice": "U",
  "success": "S"
}