<template>
  <p-dialog :value="value" max-height="fit-content" max-width="fit-content">
    <template #toolbar>
      <v-toolbar-title> Download Status</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn flat icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <v-layout row wrap id="downloadStatus-modal">
      <v-flex xs12 d-inline-block>
        <v-card class="mb-1 downloadStatus-card pt-4 text-center" id="download-status">
          <v-img :src="imageSrc" height="450px" contain></v-img>
          <v-card-title primary-title>
            <h2 class="title mb-4 width100">{{ status.title }}</h2>
          </v-card-title>
          <v-card-text class="status-text">
            <p class="subheading">
              {{ status.text }}
            </p>
            <div v-if="suspendDataSizeInBytes > 1" class="align-center justify-center" style="display: flex">
              <v-icon class="red--text mr-2">mdi-alert-circle-outline</v-icon>
              <p class="subheading red--text mb-0">
                The Scorm size is larger than the recommended (which is 64 kB).
              </p>
            </div>
          </v-card-text>

          <v-card-actions v-if="response.state == 'ready'">
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary" @click="previewUrl">Preview</v-btn> -->
            <v-btn color="primary" @click="downloadLinkSCORM">Download</v-btn>
            <v-btn outline color="error" @click="deleteSCORM"> Delete SCORM </v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <v-spacer></v-spacer>
            <v-btn outline color="primrary" @click="$emit('input', false)"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <template #actions> <div></div> </template>
  </p-dialog>
</template>

<script>
import { PDialog, PtModal } from "plant-common"
import {
  STORE_CONTENT_NAME,
  STATE_ITEM_REVISION,
  ACTION_DOWNLOAD_SCORM,
  ACTION_PREVIEW_SCORM,
  ACTION_DELETE_SCORM,
  STORE_MODULE_ACTIVITY_STATE,
  STATE_COMPRESSED_ACTIVITY_STATE
} from "@/store_constants/content"
import { mapState, mapActions } from "vuex"
import { getContentId } from "../../../utils/dbTool"

export default {
  name: "DownloadStatusModal",
  components: { PDialog },
  mixins: [PtModal],
  props: { response: Object, value: Boolean, version: String },
  data() {
    return {
      imageSrc: "",
      status: {
        title: "",
        text: ""
      },
      suspendDataSizeInBytes: 0,
      maxSuspendDataSize: 64000
    }
  },
  created() {
    this.suspendDataSizeInBytes = this.compressedActivityState.length
  },
  computed: {
    ...mapState(STORE_CONTENT_NAME, {
      itemRevision: STATE_ITEM_REVISION
    }),
    ...mapState(STORE_MODULE_ACTIVITY_STATE, {
      compressedActivityState: STATE_COMPRESSED_ACTIVITY_STATE
    }),
    SCORMVersion() {
      let scormVersion = ""
      switch (this.version) {
        case "2004v4":
          scormVersion = "2004 4th Edition"
          break
        case "2004v3":
          scormVersion = "2004 3rd Edition"
          break
        default:
          scormVersion = "1.2"
          break
      }
      return scormVersion
    }
  },
  mounted() {
    const SCORMTitle = "SCORM (v" + this.SCORMVersion + ")"
    const statusObject = {
      ready: {
        title: "Ready!",
        text: "This " + SCORMTitle + " is ready to download"
      },
      pending: {
        title: "Waiting in the queue",
        text: "Your " + SCORMTitle + " export job is in the queue and it will be processed shortly"
      },
      queued: {
        title: "Job queued!",
        text:
          "Generating a " +
          SCORMTitle +
          " file can take a while. That is why it will be processed in the background. You will receive a notification when it is ready. Stay tuned!"
      },
      running: {
        title: "Processing",
        text: "Your " + SCORMTitle + " is being built right now. It will be ready to download shortly"
      },
      error: {
        title: "Oops!",
        text: "Something happened trying to processing the request"
      }
    }
    switch (this.response.state) {
      case "ready":
        this.imageSrc = require("./resources/downloadStatus/SCORM_ready.svg")
        break
      case "pending":
        this.imageSrc = require("./resources/downloadStatus/SCORM_waiting_queue.svg")
        break
      case "queued":
        this.imageSrc = require("./resources/downloadStatus/SCORM_queued.svg")
        break
      case "running":
        this.imageSrc = require("./resources/downloadStatus/SCORM_processing.svg")
        break
      case "error":
        this.imageSrc = require("./resources/downloadStatus/SCORM_error.svg")
        break
      default:
        this.imageSrc = require("./resources/downloadStatus/SCORM_error.svg")
        return
        break
    }
    this.status = statusObject[this.response.state]
  },
  methods: {
    ...mapActions(STORE_CONTENT_NAME, {
      downloadScorm: ACTION_DOWNLOAD_SCORM,
      getScormPreviewUrl: ACTION_PREVIEW_SCORM,
      deleteScorm: ACTION_DELETE_SCORM
    }),
    async downloadLinkSCORM() {
      const downloadURL = await this.downloadScorm({
        contentId: getContentId(this.itemRevision),
        majorRev: this.itemRevision.header.major_revision,
        minorRev: this.itemRevision.header.minor_revision,
        version: this.version
      })
      window.open(downloadURL)
    },
    async previewUrl() {
      const downloadURL = await this.getScormPreviewUrl({
        contentId: getContentId(this.itemRevision),
        majorRev: this.itemRevision.header.major_revision,
        minorRev: this.itemRevision.header.minor_revision,
        version: this.version
      })
      window.open("/plant/scorm-inspector?url=" + downloadURL, "_blank").focus()
    },
    deleteSCORM() {
      this.$modal("confirm", {
        title: `Are you sure you want to delete the SCORM for this content? If you want to download it later it needs to be built again.`,
        accept: {
          color: "error",
          text: "Delete",
          icon: "mdi-delete"
        },
        cancel: { text: "cancel", color: "shades" }
      }).then(r => {
        if (!r) return
        this.deleteScorm({
          contentId: getContentId(this.itemRevision),
          majorRev: this.itemRevision.header.major_revision,
          minorRev: this.itemRevision.header.minor_revision,
          version: this.version
        })
        this.$emit("input", false)
      })
    }
  }
}
</script>

<style>
#downloadStatus-modal {
  width: 65vw;
  max-width: 1024px;
  height: 80vh;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  border-radius: 0px 0px 6px 6px;
}

.downloadStatus-card {
  height: 80vh;
  overflow: scroll;
  box-shadow: none !important;
}

.data-headline {
  color: #585858;
  font-size: 20px !important;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-dialog {
  max-height: 100% !important;
}

.width100 {
  width: 100%;
}

.status-text {
  min-height: 12vh;
}
</style>
